export const REGISTER_START = 'REGISTER_START'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const LOGIN_START = 'LOGIN_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'

export const PASSWORD_RESET_CONFIRM_START = 'PASSWORD_RESET_CONFIRM_START'
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_ERROR = 'PASSWORD_RESET_CONFIRM_ERROR'

export const ACTIVATION_START = 'ACTIVATION_START'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_ERROR = 'ACTIVATION_ERROR'

export const ACTIVATION_RESEND_START = 'ACTIVATION_RESEND_START'
export const ACTIVATION_RESEND_SUCCESS = 'ACTIVATION_RESEND_SUCCESS'
export const ACTIVATION_RESEND_ERROR = 'ACTIVATION_RESEND_ERROR'
