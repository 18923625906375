import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/wasiyyah/types'

export const getCharities = () => {
  return {
    types: [
      actions.GET_CHARITIES_START,
      actions.GET_CHARITIES_SUCCESS,
      actions.GET_CHARITIES_ERROR
    ],
    callAPI: () => authAxios().get('wasiyyah/charities/'),
  }
}

export const submitShares = (formData) => {
  return {
    types: [
      actions.SUBMIT_SHARES_START,
      actions.SUBMIT_SHARES_SUCCESS,
      actions.SUBMIT_SHARES_ERROR
    ],
    callAPI: () => authAxios().post('wasiyyah/shares/', {...formData}),
  }
}


export const getCharityShares = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.GET_CHARITY_SHARES_START,
      actions.GET_CHARITY_SHARES_SUCCESS,
      actions.GET_CHARITY_SHARES_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().get('wasiyyah/shares/charity/'),
  }
}


export const submitCharityShares = (formData) => {
  return {
    types: [
      actions.SUBMIT_CHARITY_SHARES_START,
      actions.SUBMIT_CHARITY_SHARES_SUCCESS,
      actions.SUBMIT_CHARITY_SHARES_ERROR
    ],
    callAPI: () => authAxios().post('wasiyyah/shares/charity/', {...formData}),
  }
}


export const deleteCharityShares = () => {
  return {
    types: [
      actions.DELETE_CHARITY_SHARES_START,
      actions.DELETE_CHARITY_SHARES_SUCCESS,
      actions.DELETE_CHARITY_SHARES_ERROR
    ],
    callAPI: () => authAxios().delete(`wasiyyah/shares/charity/`),
  }
}


export const getRecipientShares = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.GET_RECIPIENT_SHARES_START,
      actions.GET_RECIPIENT_SHARES_SUCCESS,
      actions.GET_RECIPIENT_SHARES_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().get('wasiyyah/shares/recipient/'),
  }
}


export const createRecipientShares = (formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.CREATE_RECIPIENT_SHARES_START,
      actions.CREATE_RECIPIENT_SHARES_SUCCESS,
      actions.CREATE_RECIPIENT_SHARES_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('wasiyyah/shares/recipient/', {...formData}),
  }
}


export const partialUpdateRecipientShares = (shareID, formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.PARTIAL_UPDATE_RECIPIENT_SHARES_START,
      actions.PARTIAL_UPDATE_RECIPIENT_SHARES_SUCCESS,
      actions.PARTIAL_UPDATE_RECIPIENT_SHARES_ERROR
    ],
    postSuccessHook: postSuccessHook,
    payload: { shareID },
    callAPI: () => authAxios().patch(`wasiyyah/shares/recipient/${shareID}/`, {...formData}),
  }
}


export const deleteRecipientShare = (shareID, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.DELETE_RECIPIENT_SHARE_START,
      actions.DELETE_RECIPIENT_SHARE_SUCCESS,
      actions.DELETE_RECIPIENT_SHARE_ERROR
    ],
    postSuccessHook: postSuccessHook,
    payload: { shareID },
    callAPI: () => authAxios().delete(`wasiyyah/shares/recipient/${shareID}/`),
  }
}

export const deleteRecipientShares = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.DELETE_RECIPIENT_SHARES_START,
      actions.DELETE_RECIPIENT_SHARES_SUCCESS,
      actions.DELETE_RECIPIENT_SHARES_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().delete(`wasiyyah/shares/recipient/all/`),
  }
}

export const getMessage = () => {
  return {
    types: [
      actions.GET_MESSAGE_START,
      actions.GET_MESSAGE_SUCCESS,
      actions.GET_MESSAGE_ERROR
    ],
    callAPI: () => authAxios().get('wasiyyah/message/'),
  }
}


export const submitMessage = (formData) => {
  return {
    types: [
      actions.SUBMIT_MESSAGE_START,
      actions.SUBMIT_MESSAGE_SUCCESS,
      actions.SUBMIT_MESSAGE_ERROR
    ],
    callAPI: () => authAxios().post('wasiyyah/message/', {...formData}),
  }
}
