import React from 'react';
import _ from 'lodash'
import { Field } from "formik";
import { FormControl, FormControlLabel, FormHelperText, RadioGroup } from '@material-ui/core'
import Radio from "@material-ui/core/Radio";
import styled from "styled-components";


const StyledFormControl = styled(FormControl)`

  .MuiFormGroup-root {
    padding-left: 5px;
  }
  
  .MuiFormHelperText-root {
    padding-left: 13px;
    margin-top: -5px;
  }
`

const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  values,
  helpText,
  horizontal,
  ...props
}) => {
  const fieldName = name || field.name;
  const isError = touched[fieldName] && errors[fieldName]

  const parsedError = () => (_.isArray(errors[fieldName]) ? errors[fieldName].join('\n') : errors[fieldName])

  return (
    <>
      <StyledFormControl component="fieldset" error={isError} c>
      <RadioGroup {...field}  name={fieldName} row={horizontal}  {...props}>
        {Object.entries(values).map(([value, label]) => (
          <FormControlLabel
            key={value}
            value={value}
            control={<Radio color="primary" />}
            label={label} />
        ))}
      </RadioGroup>
      <FormHelperText>{isError? parsedError() : helpText}</FormHelperText>
      </StyledFormControl>
    </>
  );
};


const RadioField = ({ name, formLabel, helpText, values, ...props }) => {
  return (
      <Field component={FormikRadioGroup} name={name}  color="primary" values={values} {...props}  />
    )
}

export default RadioField

