import React, { useEffect, useRef } from 'react';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import TextField from "components/atoms/TextField/TextField";
import {
  partialUpdateAccount as partialUpdateAccountAction,
  passwordSet as passwordSetAction
} from "redux/actions/account";
import { sectionSteps } from 'views/will/AboutYou/Steps'
import SectionResolver from "utils/SectionsResolver";

const stepIndex = 0
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)


const Account = ({ user, passwordSet, partialUpdateAccount, formError }) => {

  const formikRef = useRef(null);

  useEffect(() => {
    formikRef.current.setErrors(formError)
  })

  return (
    <>
    <Helmet><title>Manage account</title></Helmet>
     <Grid container direction="row" justify="center" alignItems="center" >
       <Grid item md={5} sm={8} >
        <Typography variant="h1" align="center">Manage account</Typography>
        <Formik
          innerRef={formikRef}
          initialValues={{
              current_password: '',
              new_password: '',
              email: user.email
            }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              current_password: yup.string()
                .label('Current password')
                .required(),
              new_password: yup.string()
                .label('New password')
                .required()
            })}
          onSubmit={async (values, { resetForm }) => {
            passwordSet(values, () => {
              resetForm();
            })
          }}
        >
          {({isSubmitting, values, errors}) => {
            return (
              <>
                <Box mt={5} >
                  <Box mb={1}>
                    <Typography variant="h4">Your email address</Typography>
                  </Box>
                  <TextField name="email" fullWidth disabled/>
                </Box>
                <Form>
                  <Box mt={5} >
                    <Box mb={1}>
                      <Typography variant="h4">Change your password</Typography>
                    </Box>
                    <TextField name="current_password" type="password" label="Current password"  fullWidth />
                    <TextField name="new_password" type="password" label="New Password" fullWidth />
                  </Box>
                  <Box mb={6}>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth> Change password </Button>
                  </Box>
                </Form>
                <Divider/>

                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Contact preferences</Typography>
                  </Box>
                  <FormControlLabel
                    label="Email me occasional tips, offers and updates."
                    control={
                      <Checkbox name="subscribe" color="primary"  defaultChecked={user.subscribe}  onClick={() => partialUpdateAccount({'subscribe': !user.subscribe})} />
                    }
                  />
                </Box>

              </>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global }) => ({
  user: account.user,
  formError: global.formError
});

const mapDispatchToProps = dispatch => ({
  passwordSet: (formData, postSuccessHook) => dispatch(passwordSetAction(formData, postSuccessHook)),
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
