export const GET_WILLS_START = 'GET_WILLS_START';
export const GET_WILLS_SUCCESS = 'GET_WILLS_SUCCESS';
export const GET_WILLS_ERROR = 'GET_WILLS_ERROR';

export const PARTIAL_UPDATE_WILL_START = 'PARTIAL_UPDATE_WILLS_START';
export const PARTIAL_UPDATE_WILL_SUCCESS = 'PARTIAL_UPDATE_WILLS_SUCCESS';
export const PARTIAL_UPDATE_WILL_ERROR = 'PARTIAL_UPDATE_WILLS_ERROR';

export const GET_WILL_DRAFT_START = 'GET_WILL_DRAFT_START';
export const GET_WILL_DRAFT_SUCCESS = 'GET_WILL_DRAFT_SUCCESS';
export const GET_WILL_DRAFT_ERROR = 'GET_WILL_DRAFT_ERROR';

export const GENERATE_WILL_REVISION_START = 'GENERATE_WILL_REVISION_START';
export const GENERATE_WILL_REVISION_SUCCESS = 'GENERATE_WILL_REVISION_SUCCESS';
export const GENERATE_WILL_REVISION_ERROR = 'GENERATE_WILL_REVISION_ERROR';

export const PURCHASE_WILL_START = 'PURCHASE_WILL_START';
export const PURCHASE_WILL_SUCCESS = 'PURCHASE_WILL_SUCCESS';
export const PURCHASE_WILL_ERROR = 'PAYMENT_ERROR';

export const SUBMIT_WILL_START = 'SUBMIT_WILL_START';
export const SUBMIT_WILL_SUCCESS = 'SUBMIT_WILL_SUCCESS';
export const SUBMIT_WILL_ERROR = 'SUBMIT_WILL_ERROR';
