import React from "react";
import _ from 'lodash'
import PropTypes from "prop-types";
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from "theme/mainTheme";
import NavBar from "components/organisms/NavBar/NavBar";
import Footer from "components/molecules/Footer/Footer";
import { Container, Box, CircularProgress } from "@material-ui/core";
import GlobalStyle from "theme/GlobalStyle";
import {connect} from "react-redux";
import SnackBarProvider from "utils/SnackBarProvider";
import Notifier from "utils/Notifier";

const MainTemplate = ({ children, user, isLoading }) => (

    <StylesProvider injectFirst>
      <ThemeProvider theme={ theme }>
        <SnackBarProvider maxSnack={3}>
          <CssBaseline />
          <GlobalStyle isLogin={!_.isEmpty(user)}/>
          <Notifier/>
          <NavBar />
          {isLoading?
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>:
            <Container fixed>
              { children }
              <Footer/>
            </Container>
          }

        </SnackBarProvider>
      </ThemeProvider>
    </StylesProvider>
)

MainTemplate.propTypes = {
    children: PropTypes.element.isRequired,
}

const mapStateToProps = ({ account, global }) => ({
  user: account.user,
  isLoading: global.contentLoading,
});


export default connect(
  mapStateToProps,
)(MainTemplate);
