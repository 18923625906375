import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import * as yup from 'yup'
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from 'routes'
import { Form, Formik } from 'formik';
import { login as loginAction } from 'redux/actions/auth/Login';
import TextField from 'components/atoms/TextField/TextField'
import { NavigateNext } from "@material-ui/icons";
import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import PrivacyNote from "components/molecules/PrivacyNote/PrivacyNote";
import segmentTrack from "utils/SegmentIO";

const Login = ({user, login, formError}) => {

  const formikRef = useRef(null);

  useEffect(() => {
    formikRef.current.setErrors(formError)
  })

  return (
    <>
      <Helmet>
        <title>Log in</title>
        <meta property="og:title" content="Log in" />
        <meta name="segment-event-name" content="Log in | Viewed page" />
      </Helmet>
      <Grid container direction="row" justify="center" alignItems="center" >
       <Grid item md={5} sm={8} >
         <Typography variant="h1" align="center" >Log in</Typography>
         <br/>
         <Formik
            innerRef={formikRef}
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema= {
              yup.object().shape({
                email: yup.string()
                  .label('Email')
                  .required()
                  .email(),
                password: yup.string()
                  .label('Password')
                  .required()
            })}

            onSubmit={async ( values) => {
              await login(values.email.toLowerCase(), values.password)
              segmentTrack("Log in | Submitted form", {
                'is_subscribed': values.subscribe,
                'attempts': formikRef.current.submitCount
              })
            }}
         >
            {({ isSubmitting, touched, errors }) => {
              return (
                <Form >
                  <div>
                    <TextField name="email" label="Email" fullWidth />
                  </div>
                  <div>
                    <TextField name="password" type="password" label="Password" className="multiline" fullWidth />
                  </div>
                  <div>
                    <br/>
                    <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth> Log in <NavigateNext /> </Button>
                  </div>
                </Form>
              )
            }}
         </Formik>
         <Box textAlign="center" mt={3}>
           <Typography align="center" className="font-secondary"> Forgot your password?  <Link component={RouterLink} to={routes.passwordReset}>Reset password</Link></Typography>
         </Box>
          <Box textAlign="center"  mb={2}>
           <Typography align="center"  className="font-secondary"> Don't have a True Wills account?  <Link component={RouterLink} to={routes.start}>Sign up</Link></Typography>
         </Box>
         <Box mt={3} >
           <PrivacyNote />
         </Box>
       </Grid>
     </Grid>
    </>
  )
};

const mapStateToProps = ({ account, global }) => ({
  user: account.user,
  formError: global.formError,
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginAction(email, password)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

