import React from 'react';
import _ from 'lodash'
import { Box, Divider, Grid, Link, Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";

const StyledFooter = styled.footer`
  color: ${theme.palette.grey.A200};
  font-size: 1.6rem;
  ${theme.breakpoints.down("sm")}{
    text-align: center;
  }
  margin-top: 50px;
`
const StyledLink = styled(Link)`
  color: ${theme.palette.grey.A200} !important;
  font-size: ${theme.typography.fontSize.normal};
`

const StyledShare = styled.div`
  text-align: right;
  ${theme.breakpoints.down("sm")}{
    text-align: center;
    margin-top: 10px;
  }
`
const StyledLinkUnderline = styled(Link)`
  color: ${theme.palette.grey.A200} !important;
  text-decoration: underline;
  font-size: 1.6rem;
`

const Footer = ({ user }) => {

  return (
    <StyledFooter>
      <Box component="p" mb={1}>
        <Divider  />
      </Box>
      <Box mb={2}>
        <Grid container >
          <Grid item md={6} xs={12} >
            <Box component="span" mr={3}>
              <StyledLink target='_blank' href='https://truewills.co.uk/privacy'>Privacy Policy</StyledLink>
            </Box>
              <StyledLink target='_blank' href='https://truewills.co.uk/terms' >Terms of service</StyledLink>
          </Grid>
          {!_.isEmpty(user) &&
          <Grid item md={6} xs={12} >
            <StyledShare>Help us improve, <StyledLinkUnderline target='_blank' href='https://forms.gle/yChWCiMeeRp6njJe8'>share your feedback</StyledLinkUnderline></StyledShare>
          </Grid>
          }
        </Grid>
      </Box>
    </StyledFooter>
  )
};

const mapStateToProps = ({ account }) => ({
  user: account.user,
});


export default connect(
  mapStateToProps
)(Footer);


