import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isLogin from 'utils/isLogin';
import { routes } from 'routes'

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
      <>
        <Route {...rest} render={props => (
            isLogin() ?
                <Component {...props} />
            : <Redirect to={routes.login} />
        )} />

        </>
    );
};

export default PrivateRoute;
