
export const birthDateSplit = (date) => {
    if (date){
        let splitDate = date.split('-')
        return [splitDate[2], splitDate[1], splitDate[0]]
    } else return ['', '', '']

}

export const birthDateJoin = (day, month, year) => {
    return `${year}-${month}-${day}`
}

