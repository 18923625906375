import React from "react";
import { Box, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import Paper from "components/atoms/Paper/Paper";


const InviteBox = () => {

  return (
     <Paper>
      <Box>
        <p className="font-primary">
          <b><Link component={RouterLink} to={routes.invite}>Invite others to make their Islamic will</Link></b>
        </p>
        <p  className="font-secondary">
          The Prophet (s) said, “Whoever guides someone to goodness will have a reward like one who did it.” (Muslim)
        </p>
      </Box>
    </Paper>
  )
}

export default InviteBox

