import * as actions from 'redux/actions/people/types'
import update from 'immutability-helper';

const initialState = {
  people: [],
  partners: [],
  executors: [],
  children: [],
  guardians: []
}

const peopleReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_PEOPLE_START:
      return {...state}
    case actions.GET_PEOPLE_SUCCESS:
      return {...state, people: action.data}
    case actions.GET_PEOPLE_ERROR:
      return {...state}

    case actions.CREATE_PERSON_START:
      return {...state}
    case actions.CREATE_PERSON_SUCCESS:
      return {...state, people: [...state.people, action.data]}
    case actions.CREATE_PERSON_ERROR:
      return {...state}

    case actions.PARTIAL_UPDATE_PERSON_START:
      return {...state}
    case actions.PARTIAL_UPDATE_PERSON_SUCCESS:
      return update(state, {
        people: {
          [state.people.findIndex(person => person.id === action.personID)] : {
            $set: action.data }
          }
      })
    case actions.PARTIAL_UPDATE_PERSON_ERROR:
      return {...state}

    case actions.DELETE_PERSON_START:
      return {...state}
    case actions.DELETE_PERSON_SUCCESS:
      return update(state, {
        people: {$splice: [[state.people.findIndex(person => person.id === action.personID), 1]]},
        partners: {$splice: [[state.people.findIndex(person => person.id === action.personID), 1]]},
        executors: {$splice: [[state.people.findIndex(person => person.id === action.personID), 1]]}
      })
    case actions.DELETE_PERSON_ERROR:
      return {...state}

    case actions.GET_PARTNERS_START:
      return {...state}
    case actions.GET_PARTNERS_SUCCESS:
      return {...state, partners: [...action.data]}
    case actions.GET_PARTNERS_ERROR:
      return {...state}

    case actions.LINK_PARTNER_START:
      return {...state}
    case actions.LINK_PARTNER_SUCCESS:
      return {...state, partners: [...state.partners, action.data.partner_id]}
    case actions.LINK_PARTNER_ERROR:
      return {...state}

    case actions.UNLINK_PARTNER_START:
      return {...state}
    case actions.UNLINK_PARTNER_SUCCESS:
      return {...state, partners: state.partners.filter(value => (value === action.data.id))}
    case actions.UNLINK_PARTNER_ERROR:
      return {...state}

    case actions.GET_EXECUTORS_START:
      return {...state}
    case actions.GET_EXECUTORS_SUCCESS:
      return {...state, executors: action.data}
    case actions.GET_EXECUTORS_ERROR:
      return {...state}

    case actions.LINK_EXECUTOR_START:
      return {...state}
    case actions.LINK_EXECUTOR_SUCCESS:
      return {...state, executors: [...state.executors, action.data]}
    case actions.LINK_EXECUTOR_ERROR:
      return {...state}

    case actions.UNLINK_EXECUTOR_START:
      return {...state}
    case actions.UNLINK_EXECUTOR_SUCCESS:
      return {...state, executors: state.executors.filter(executor => executor.id !== action.executorID )}
    case actions.UNLINK_EXECUTOR_ERROR:
      return {...state}

    case actions.GET_CHILDREN_START:
      return {...state}
    case actions.GET_CHILDREN_SUCCESS:
      return {...state, children: action.data}
    case actions.GET_CHILDREN_ERROR:
      return {...state}

    case actions.CREATE_CHILD_START:
      return {...state}
    case actions.CREATE_CHILD_SUCCESS:
      return {...state, children: [...state.children, action.data]}
    case actions.CREATE_CHILD_ERROR:
      return {...state}

    case actions.PARTIAL_UPDATE_CHILD_START:
      return {...state}
    case actions.PARTIAL_UPDATE_CHILD_SUCCESS:
      return update(state, {
        children: {
          [state.children.findIndex(child => child.id === action.childID)] : {
            $set: action.data }
          }
      })
    case actions.PARTIAL_UPDATE_CHILD_ERROR:
      return {...state}

    case actions.DELETE_CHILD_START:
      return {...state}
    case actions.DELETE_CHILD_SUCCESS:
      return update(state, {
        children: {$splice: [[state.children.findIndex(child => child.id === action.childID), 1]]},
        executors: {$splice: [[state.executors.findIndex(executor => executor.id === action.childID), 1]]}
      })
    case actions.DELETE_CHILD_ERROR:
      return {...state}

    case actions.DELETE_ALL_CHILDREN_START:
      return {...state}
    case actions.DELETE_ALL_CHILDREN_SUCCESS:
      return update(state, {
        children: {$set: []},
        executors: {$splice: [[state.executors.findIndex(executor => executor.child !== null), 1]]}
      })
    case actions.DELETE_ALL_CHILDREN_ERROR:
      return {...state}

    case actions.GET_GUARDIANS_START:
      return {...state}
    case actions.GET_GUARDIANS_SUCCESS:
      return {...state, guardians: action.data}
    case actions.GET_GUARDIANS_ERROR:
      return {...state}

    case actions.LINK_GUARDIAN_START:
      return {...state}
    case actions.LINK_GUARDIAN_SUCCESS:
      return {...state, guardians: [...state.guardians, action.data.guardian_id]}
    case actions.LINK_GUARDIAN_ERROR:
      return {...state}

    case actions.UNLINK_GUARDIAN_START:
      return {...state}
    case actions.UNLINK_GUARDIAN_SUCCESS:
      return {...state, guardians: state.guardians.filter(guardian => guardian !== action.guardianID )}
    case actions.UNLINK_GUARDIAN_ERROR:
      return {...state}

    default:
      return state
  }
};

export default peopleReducer

