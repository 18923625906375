import React from 'react';
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SelectField from "components/atoms/SelectField/SelectField";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import CheckboxField from "components/atoms/CheckboxField/CheckboxField";
import Paper from "components/atoms/Paper/Paper";
import { routes } from "routes";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AboutYou/Steps";
import SectionResolver from "utils/SectionsResolver";
import TextField from "components/atoms/TextField/TextField";

const stepIndex = 5
const [sectionName, section] = SectionResolver(sectionSteps, stepIndex)

const OtherRelatives = ({ user, partialUpdateAccount, partialUpdateWill, will, getWills }) => {

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container spacing={5} >
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <Formik
          initialValues={{
            parents_details: user.parents_details,
            relatives_details: user.relatives_details,
            relatives_siblings: user.relatives_siblings_brothers > 0 || user.relatives_siblings_sisters > 0  ? "yes" : "no",
            relatives_siblings_brothers: user.relatives_siblings_brothers || 0,
            relatives_siblings_sisters: user.relatives_siblings_sisters || 0,
            relatives_brothers_sons: user.relatives_brothers_sons || 0,
            relatives_father: user.relatives_father_brothers > 0 || user.relatives_father_brothers_sons > 0 ? "yes" : "no",
            relatives_father_brothers: user.relatives_father_brothers || 0,
            relatives_father_brothers_sons: user.relatives_father_brothers_sons || 0
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              parents_details: yup.string()
                .label('Parents')
                .required(),
              relatives_siblings_brothers: yup.string().when('relatives_siblings', {
                is: (relatives_siblings) => relatives_siblings === 'yes',
                then: yup.string().label("Brothers").required()
              }),
              relatives_siblings_sisters: yup.string().when('relatives_siblings', {
                is: (relatives_siblings) => relatives_siblings === 'yes',
                then: yup.string().label("Sisters").required()
              }),
              relatives_brothers_sons: yup.string().when('relatives_siblings_brothers', {
                is: (relatives_siblings_brothers) => parseInt(relatives_siblings_brothers) > 0,
                then: yup.string().label("Brother(s) sons").required()
              })
            }

          )}

          onSubmit={async(values, { setSubmitting }) => {
            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            if(values.relatives_siblings === "no"){
              values.relatives_siblings_brothers = 0
              values.relatives_siblings_sisters = 0
            }

            if(parseInt(values.relatives_siblings_brothers) === 0){
              values.relatives_brothers_sons = 0
            }

            if(values.relatives_father === "no"){
              values.relatives_father_brothers = 0
              values.relatives_father_brothers_sons = 0
            }

            await partialUpdateAccount(values)

            getWills()
          }}
        >

          {({isSubmitting, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5} mb={1}>
                  <Box mb={1} >
                    <Typography variant="h4">Do you have any siblings? </Typography>
                  </Box>
                  <SelectField
                    name="relatives_siblings"
                    values={{
                      "yes": "Yes",
                      "no": "No",
                    }}
                    fullWidth
                  />
                </Box>

                {values.relatives_siblings === 'yes' &&
                  <>
                    <Box mt={3}>
                      <Box mb={1}>
                        <Typography variant="h4">How many siblings do you have?</Typography>
                      </Box>
                      <Box display="flex" justifyContent="space-between" alignItems="top">
                        <TextField name="relatives_siblings_brothers" type="number" label="Brothers" fullWidth/>&nbsp;
                        <TextField name="relatives_siblings_sisters" type="number" label="Sisters" fullWidth/>
                      </Box>
                    </Box>
                    {parseInt(values.relatives_siblings_brothers) > 0 &&
                      <Box mt={3}>
                        <Box mb={1}>
                          <Typography variant="h4">Do any of your brothers have sons?</Typography>
                        </Box>
                        <TextField name="relatives_brothers_sons" type="number" label="Brother's son(s)"
                                   helpText="The total number of nephews you have" fullWidth/>
                      </Box>
                    }
                  </>
                }

                <Box mt={5} mb={1}>
                  <Box mb={1} >
                    <Typography variant="h4">Does your father have any brothers?</Typography>
                  </Box>
                  <SelectField
                    name="relatives_father"
                    values={{
                      "yes": "Yes",
                      "no": "No",
                    }}
                    fullWidth
                  />

                  {values.relatives_father === 'yes' &&
                    <>
                      <Box mt={3}>
                        <Box mb={1}>
                          <Typography variant="h4">How many full brothers does your father have?</Typography>
                        </Box>
                          <TextField name="relatives_father_brothers" type="number" label="Father's full brother(s)" helpText="The total number of uncles you have" fullWidth/>
                      </Box>
                      <Box mt={3}>
                        <Box mb={1}>
                          <Typography variant="h4">How many sons do your father's full brothers have?</Typography>
                        </Box>
                        <TextField name="relatives_father_brothers_sons" type="number" label="Father's full brother's son(s)" helpText="The total number of male cousins you have" fullWidth/>
                      </Box>
                    </>
                  }
                </Box>

                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Grid item sm={12} xs={12} md={4}>&nbsp;</Grid>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wills }) => ({
  user: account.user,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, routes.will)),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  getWills: () => dispatch(getWillsAction()),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherRelatives);
