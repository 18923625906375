import React, { useState } from "react";
import styled from "styled-components";
import { Collapse, Hidden, Link } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import { theme } from "theme/mainTheme";

const StyledAlert = styled(Alert)`
  margin-top: 10px;
  border: 1px solid #485156;
  background-color: #f3f3f3;
 
  .text{
    font-size: 1.6rem;
  }
  .MuiAlert-message{
    padding-bottom: 0;
  }
  
  .MuiAlert-message, .MuiAlert-icon, a{
    color: ${theme.palette.text.primary};
  }
  
  .MuiCollapse-container{
    margin-left: -30px;
  }
`

const StyledLink = styled(Link)`
  float: right;
  font-weight: normal;
`


const MobileHelpText = ({text, collapsable = true, collapsedHeight = 45}) => {
  const [helpTextOpen, setHelpTextOpen] = useState(!collapsable);

  const toggleExpand = () => {
    setHelpTextOpen(!helpTextOpen);
  };

  return (
    <>
       <Hidden mdUp>
         <StyledAlert severity="info">
           <AlertTitle onClick={() => toggleExpand()}>Info
             {collapsable && <StyledLink>Read more</StyledLink> }
           </AlertTitle>
            <Collapse in={helpTextOpen} >
             <p  className='text font-secondary' dangerouslySetInnerHTML={{__html: text}} />
           </Collapse>
        </StyledAlert>
      </Hidden>
    </>
  )
}

export default MobileHelpText

