export const GET_PEOPLE_START = 'GET_PEOPLE_START';
export const GET_PEOPLE_SUCCESS = 'GET_PEOPLE_SUCCESS';
export const GET_PEOPLE_ERROR = 'GET_PEOPLE_ERROR';

export const CREATE_PERSON_START = 'CREATE_PERSON_START';
export const CREATE_PERSON_SUCCESS = 'CREATE_PERSON_SUCCESS';
export const CREATE_PERSON_ERROR = 'CREATE_PERSON_ERROR';

export const PARTIAL_UPDATE_PERSON_START = 'PARTIAL_UPDATE_PERSON_START';
export const PARTIAL_UPDATE_PERSON_SUCCESS = 'PARTIAL_UPDATE_PERSON_SUCCESS';
export const PARTIAL_UPDATE_PERSON_ERROR = 'PARTIAL_UPDATE_PERSON_ERROR';

export const DELETE_PERSON_START = 'DELETE_PERSON_START';
export const DELETE_PERSON_SUCCESS = 'DELETE_PERSON_SUCCESS';
export const DELETE_PERSON_ERROR = 'DELETE_PERSON_ERROR';

export const GET_PARTNERS_START = 'GET_PARTNERS_START';
export const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
export const GET_PARTNERS_ERROR = 'GET_PARTNERS_ERROR';

export const LINK_PARTNER_START = 'LINK_PARTNER_START';
export const LINK_PARTNER_SUCCESS = 'LINK_PARTNER_SUCCESS';
export const LINK_PARTNER_ERROR = 'LINK_PARTNER_ERROR';

export const UNLINK_PARTNER_START = 'UNLINK_PARTNER_START';
export const UNLINK_PARTNER_SUCCESS = 'UNLINK_PARTNER_SUCCESS';
export const UNLINK_PARTNER_ERROR = 'UNLINK_PARTNER_ERROR';

export const GET_EXECUTORS_START = 'GET_EXECUTORS_START';
export const GET_EXECUTORS_SUCCESS = 'GET_EXECUTORS_SUCCESS';
export const GET_EXECUTORS_ERROR = 'GET_EXECUTORS_ERROR';

export const LINK_EXECUTOR_START = 'LINK_EXECUTOR_START';
export const LINK_EXECUTOR_SUCCESS = 'LINK_EXECUTOR_SUCCESS';
export const LINK_EXECUTOR_ERROR = 'LINK_EXECUTOR_ERROR';

export const UNLINK_EXECUTOR_START = 'UNLINK_EXECUTOR_START';
export const UNLINK_EXECUTOR_SUCCESS = 'UNLINK_EXECUTOR_SUCCESS';
export const UNLINK_EXECUTOR_ERROR = 'UNLINK_EXECUTOR_ERROR';

export const GET_CHILDREN_START = 'GET_CHILDREN_START';
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';
export const GET_CHILDREN_ERROR = 'GET_CHILDREN_ERROR';

export const CREATE_CHILD_START = 'CREATE_CHILD_START';
export const CREATE_CHILD_SUCCESS = 'CREATE_CHILD_SUCCESS';
export const CREATE_CHILD_ERROR = 'CREATE_CHILD_ERROR';

export const PARTIAL_UPDATE_CHILD_START = 'PARTIAL_UPDATE_CHILD_START';
export const PARTIAL_UPDATE_CHILD_SUCCESS = 'PARTIAL_UPDATE_CHILD_SUCCESS';
export const PARTIAL_UPDATE_CHILD_ERROR = 'PARTIAL_UPDATE_CHILD_ERROR';

export const DELETE_CHILD_START = 'DELETE_CHILD_START';
export const DELETE_CHILD_SUCCESS = 'DELETE_CHILD_SUCCESS';
export const DELETE_CHILD_ERROR = 'DELETE_CHILD_ERROR';

export const DELETE_ALL_CHILDREN_START = 'DELETE_ALL_CHILDREN_START';
export const DELETE_ALL_CHILDREN_SUCCESS = 'DELETE_ALL_CHILDREN_SUCCESS';
export const DELETE_ALL_CHILDREN_ERROR = 'DELETE_ALL_CHILDREN_ERROR';

export const GET_GUARDIANS_START = 'GET_GUARDIANS_START';
export const GET_GUARDIANS_SUCCESS = 'GET_GUARDIANS_SUCCESS';
export const GET_GUARDIANS_ERROR = 'GET_GUARDIANS_ERROR';

export const LINK_GUARDIAN_START = 'LINK_GUARDIAN_START';
export const LINK_GUARDIAN_SUCCESS = 'LINK_GUARDIAN_SUCCESS';
export const LINK_GUARDIAN_ERROR = 'LINK_GUARDIAN_ERROR';

export const UNLINK_GUARDIAN_START = 'UNLINK_GUARDIAN_START';
export const UNLINK_GUARDIAN_SUCCESS = 'UNLINK_GUARDIAN_SUCCESS';
export const UNLINK_GUARDIAN_ERROR = 'UNLINK_CHILD_GUARDIAN_ERROR';
