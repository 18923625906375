import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import authReducer from "redux/reducers/auth";
import accountReducer from "redux/reducers/account";
import alertsReducer from "redux/reducers/alerts";
import assetReducer from "redux/reducers/assets";
import peopleReducer from "redux/reducers/people";
import willsReducer from "redux/reducers/wills";
import snackbarReducer from "redux/reducers/snackbar"
import wasiyyahReducer from "redux/reducers/wasiyyah";
import configReducer from "redux/reducers/config";
import * as actions from "redux/actions/root/types";

const initialState = {
  formError: false,
  contentLoading: true
}

const globalReducer = (state = initialState, action) => {
  switch (action.type){
    case actions.SET_GLOBAL_FORM_ERROR:
      return {...state, formError: action.formError.response? action.formError.response.data: false }
    case actions.SET_CONTENT_LOADING:
      return {...state, contentLoading: true}
    case actions.UNSET_CONTENT_LOADING:
      return {...state, contentLoading: false}
    default:
      return state
  }

};

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  global: globalReducer,
  auth: authReducer,
  account: accountReducer,
  alerts: alertsReducer,
  asset: assetReducer,
  people: peopleReducer,
  wills: willsReducer,
  wasiyyah: wasiyyahReducer,
  notifications: snackbarReducer,
  config: configReducer

});

export default createRootReducer
