import * as actions from "redux/actions/root/types";

export const setGlobalFormError = (error) => dispatch => {
  dispatch({ type: actions.SET_GLOBAL_FORM_ERROR, formError: error });
}

export const setContentLoading = () => dispatch => {
  dispatch({ type: actions.SET_CONTENT_LOADING });
}

export const unsetContentLoading = () => dispatch => {
  dispatch({ type: actions.UNSET_CONTENT_LOADING });
}
