import { routes } from 'routes'


export const sectionSlug = 'executors'

export const sectionSteps = {
  "executors": {
    "label": "Executors and trustees",
    "url": routes.executorsIntroduction,
  }
}
