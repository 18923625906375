import React from 'react';
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import TextField from "components/atoms/TextField/TextField";
import SelectField from "components/atoms/SelectField/SelectField";
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { birthDateJoin, birthDateSplit } from "utils/birthDateParser";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from 'views/will/AboutYou/Steps'
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SectionResolver from "utils/SectionsResolver";

const stepIndex = 0
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)


const AboutYouNames = ({ user, partialUpdateAccount, will, getWills, partialUpdateWill}) => {

  const [birthday_day, birthday_month, birthday_year] = birthDateSplit(user.birth_date)

  return (
    <>
    <Helmet>
      <title>{section["label"]}</title>
      <meta name="segment-event-name" content="MIW | Viewed about you page" />
    </Helmet>
    <Grid container spacing={5} >
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <Formik
          initialValues={{
            full_name: user.full_name,
            preferred_name: user.preferred_name,
            gender: user.gender,
            birthday_day: birthday_day,
            birthday_month: birthday_month,
            birthday_year: birthday_year
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              full_name: yup.string()
                .label('Full legal name')
                .required(),
              gender: yup.string()
                .label('Gender')
                .required(),
              preferred_name: yup.string()
                .label('Preferred Name')
                .required(),
              birthday_day: yup.number().typeError('DD').min(1, 'DD').max(31, 'DD')
                  .label('Day')
                  .required('DD'),
              birthday_month: yup.number().typeError('MM').min(1, 'MM').max(12, 'MM')
                  .label('Month')
                  .required('MM'),
              birthday_year: yup.number().typeError('YYYY').max(new Date().getFullYear(), 'YYYY').test('len', 'YYYY', val => val && val.toString().length === 4 )
                  .label('Year')
                  .required('YYYY'),
            })}

          onSubmit={async (values) => {

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            values["birth_date"] = birthDateJoin(values.birthday_day, values.birthday_month, values.birthday_year)
            await partialUpdateAccount(values)

            getWills()

          }}
        >
          {({isSubmitting, touched, errors}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your full legal name?</Typography>
                  </Box>
                  <TextField name="full_name" label="E.g. Ahmed Abdullah" helpText="This is the name on the passport or driving license." fullWidth/>
                </Box>
                <Box mt={3}>
                  <Box mb={1}>
                    <Typography variant="h4">What would you like us to call you?</Typography>
                  </Box>
                  <TextField name="preferred_name" label="Preferred name" helpText="For example if we emailed you or talked to you on the phone." fullWidth/>
                </Box>
                <Box mt={3}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your date of birth?</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField type="number" name="birthday_day" label="Day" helpText="DD"/>&nbsp;
                    <TextField type="number" name="birthday_month" label="Month" helpText="MM"/>&nbsp;
                    <TextField type="number" name="birthday_year" label="Year" helpText="YYYY"/>
                  </Box>
                </Box>
                <Box mt={3} mb={5}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your gender?</Typography>
                  </Box>
                  <SelectField
                    name="gender"
                    label="Gender"
                    values={{
                      "male": "Male",
                      "female": "Female"
                    }}
                    fullWidth
                  />
                </Box>
                <Box mb={2}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Grid item sm={12} xs={12} md={4}>&nbsp;</Grid>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wills }) => ({
  user: account.user,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, nextSection["url"])),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  getWills: () => dispatch(getWillsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYouNames);
