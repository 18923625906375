import * as actions from "./types";
import axios from "axios";
import { login } from 'redux/actions/auth/Login'
import { partialUpdateAccount } from 'redux/actions/account'
import { routes } from "routes";

export const register = (email, password, preferred_name, subscribe) => {
  return {
    types: [
      actions.REGISTER_START,
      actions.REGISTER_SUCCESS,
      actions.REGISTER_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/`,{ email, password, preferred_name, subscribe }),
    successMessages: [
      { variant: "success", message: "Your account has been registered successfully"},
    ],
    postSuccessHook: async(payload, dispatch) => {
      await dispatch(login(email, password))

      const registration = {}
      for (let item in localStorage) {
        if(item.includes('question')){
          registration[item.replace("question: ", "")] = localStorage[item]
          localStorage.removeItem(item)
        }
      }
      dispatch(partialUpdateAccount({"registration": registration }))
      dispatch(partialUpdateAccount({"referer": localStorage.getItem('refID')}))

    }
}}
