import React from "react";
import styled, { css } from "styled-components";
import { theme } from "theme/mainTheme";
import { Box } from "@material-ui/core";

const Item = styled.span`
  display: block;
  width: 100%;
  margin: 0 2px;
  height: 8px;
  -webkit-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  background-color: ${({value}) => value ? theme.palette.primary.main : theme.palette.grey["300"]};

  ${({variant}) => (
    variant === 'circle' && css`
      margin: 0 3px;
      width: 15px;
      height: 15px;
      -webkit-border-radius: 15px 15px 15px 15px;
      border-radius: 15px 15px 15px 15px;
    `
  )}
  
  ${({variant}) => (
  variant === 'solid' && css`
      margin: 0 3px;
      width: 100%;
      height: 15px;
      -webkit-border-radius: 15px 15px 15px 15px;
      border-radius: 15px 15px 15px 15px;
      background-color: ${theme.palette.grey["300"]};
      
      
      .progress {
        display: block;
        height: 15px;
        width: ${({ value }) => value}%;
        background-color: red;
        -webkit-border-radius: 15px 15px 15px 15px;
        border-radius: 15px 15px 15px 15px;
        background-color: ${theme.palette.success.main};
      }
      
    `
)}
  
  
`;

const ProgressBar = ({max, value, variant='default'}) => {

  return (
    <Box display="flex" justifyContent={variant === 'circle' || 'solid' ? 'center': 'space-between'} alignItems="center">

      {['default', 'circle'].includes(variant) &&
        [...Array(max)].map((e, i) => {
          return <Item variant={variant} value={i < value}>&nbsp;</Item>;
        })
      }

      { variant === 'solid' &&
        <Item variant={variant} value={value}><span className="progress">&nbsp;</span></Item>
      }

    </Box>
  )
}

export default ProgressBar
