import React from "react";
import _ from 'lodash'
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import { Eye, CheckSquare } from 'react-feather';
import WillDownload from "components/molecules/WillDownload/WillDownload";
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import segmentTrack from "utils/SegmentIO";
import WillSubmit from "components/molecules/WillSubmit/WillSubmit";


const StyledWillDownload = styled(WillDownload) `
  background-color: ${theme.palette.success.main};
  width: 100%;
  color: white;
  &:hover{
    background-color: ${theme.palette.success.main};
  }
`



const YourWill = ({user, will }) => {
  return (
    <>
        {!_.isEmpty(will) && !will.valid_summary['all'] && will.status === null &&
          <>
            <Typography variant="h1"> You're almost there!</Typography>
            <p className="font-secondary" >Complete all steps before submitting your Islamic will for expert checking.</p>
          </>
        }

        {!_.isEmpty(will) && will.valid_summary['all'] && will.status === null &&
          <>
            <Typography variant="h1">Congratulations! Your Islamic will is now ready for expert checking.</Typography>
            <p className="font-secondary">
              {user.is_active ?
                "Upon payment, our in-house solicitor will check your will."
              : "We need you to confirm your email address before you can submit your will for checking."}
            </p>

            <Box mt={3} mb={3}>
              <Typography variant="h4">Total to pay <span style={{'float': 'right'}}>&pound;{will.price / 100}</span></Typography>
              <i className="text-muted"> <small>Includes unlimited changes for a whole year, even after purchase, absolutely free.</small> </i>
            </Box>

            <Button component={RouterLink} to={routes.willPreview} onClick={() => segmentTrack("MIW | Clicked preview and send for checking")} type="submit" variant="contained" color="primary" disableElevation fullWidth startIcon={<CheckSquare size={20}/>}> Preview and send for checking</Button>
          </>
        }

        {!_.isEmpty(will) &&  will.valid_summary['all'] && will.paid && will.status === 'reviewing' &&
          <>
            <Typography variant="h1">Your Islamic will is now being checked by our in-house solicitor.</Typography>
            <p className="font-secondary">You will be notified via email when your will is ready to be downloaded. You can make changes to your Islamic will for free at anytime in the first year.</p>

            <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation fullWidth startIcon={<Eye/>}>Preview your will</Button>
          </>
        }

        {!_.isEmpty(will) && will.paid && will.status === 'change_requested' &&
          <>
            <Typography variant="h1">You need to make changes to your Islamic will.</Typography>
            <p className="font-secondary">Once you resubmit your will, you will be notified via email when it's ready to be downloaded.</p>

            <Box mt={2} display="flex" justifyContent="space-between">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6}>

                  <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation fullWidth startIcon={<Eye/>}>Preview your will</Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <WillSubmit buttonText="Re-submit your will"/>
                </Grid>
              </Grid>
            </Box>
          </>
        }

        { !_.isEmpty(will) && will.paid && will.status === 'approved' &&
          <>
            <Typography variant="h1">
              {will.changed && will.valid_summary['all']? "You have made changes to your Islamic will." : "Your Islamic will is ready!" }
            </Typography>
            {will.changed && will.valid_summary['all'] ?
              <>
                <p className="font-secondary">Your updated will is available immediately. You must now print and sign your updated Islamic will in front of two witnesses to make it legally binding.</p>
              </>
              :
              <p className="font-secondary">You must now print and sign your Islamic will in front of two witnesses to make it legally binding.</p>
            }

            <Box mt={2} display="flex" justifyContent="space-between">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button component={RouterLink} to={routes.willPreview}  variant="contained" color="secondary" disableElevation
                        fullWidth startIcon={<Eye/>} align="center">Preview your will</Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <StyledWillDownload fullWidth buttonText="Download your will" />
                </Grid>
              </Grid>

            </Box>
          </>
        }

    </>
  )
}

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


export default connect(
  mapStateToProps,
)(YourWill);


