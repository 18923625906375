import axios from 'axios'
import { routes } from 'routes'

const authAxios = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Authorization: `Bearer ${localStorage.JWTAccessToken}`
    }
  });

  instance.interceptors.response.use(response => {
    return response;
  }, err => {
    if ( err.response && err.response.status === 403 && err.config && !err.config.__isRetryRequest ){
      return new Promise((resolve, reject) => {
        const originalReq = err.config;

        originalReq._retry = true;

        let res = fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/jwt/refresh`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem("JWTRefreshToken")}`
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify({
              refresh: localStorage.getItem("JWTRefreshToken"),
        }),
        }).then(payload => payload.json()).then(payload => {
          localStorage.setItem('JWTAccessToken', payload.access)
          originalReq.headers['Authorization'] = `Bearer ${payload.access}`;
          return axios(originalReq);
        }).catch(err => {
          localStorage.removeItem("JWTRefreshToken")
          localStorage.removeItem("JWTAccessToken")
          window.location = routes.login
          if ( err.response && err.response.status === 403 ) return false
        });

      resolve(res);
      return Promise.reject(err);
    });

    }
    return Promise.reject(err);
  });

  return instance
}


export default authAxios


