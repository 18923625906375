import React , { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash'
import { useField } from "formik";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText} from '@material-ui/core'
import { theme } from 'theme/mainTheme'


const StyledFormControl = styled(FormControl)`
  .MuiSelect-root{
      background-color: white;
  
  }
  .MuiInputLabel-root {
    color: ${theme.palette.text.muted};
  }
  
    fieldset {
    border-width: 2px;
  }
  
  .MuiSvgIcon-root{
    fill: ${theme.palette.primary.main};
  }

`


const SelectField = ({ name, helpText, label, values, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const parsedError = () => (_.isArray(meta.error) ? meta.error.join('\n') : meta.error)

  return (
    <StyledFormControl variant="outlined" error={meta.touched && Boolean(meta.error)} {...props}>
        <InputLabel>{label}</InputLabel>
        <Select
          id={field.name}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          label={label}
          {...props}
        >
          {
            Object.entries(values).map(([value, label]) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))
          }
        </Select>
        <FormHelperText>{meta.touched && Boolean(meta.error)? parsedError() : helpText}</FormHelperText>
      </StyledFormControl>


  )
}

export default SelectField

