import _ from 'lodash'
import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/people/types'

export const getPeople = () => {
  return {
    types: [
      actions.GET_PEOPLE_START,
      actions.GET_PEOPLE_SUCCESS,
      actions.GET_PEOPLE_ERROR
    ],
    callAPI: () => authAxios().get('people/people/'),
  }
}

export const createPerson = (formData, personType, postSuccessHook = () => {} ) => {
  return {
    types: [
      actions.CREATE_PERSON_START,
      actions.CREATE_PERSON_SUCCESS,
      actions.CREATE_PERSON_ERROR
    ],
    callAPI: () => authAxios().post('people/people/', {...formData}),
    postSuccessHook: postSuccessHook,
    successMessages: [{variant: "success", message: `${_.upperFirst(personType.split('_').join(' '))} has been added`}],
  }
}

export const partialUpdatePerson = (personID, formData, personType, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.PARTIAL_UPDATE_PERSON_START,
      actions.PARTIAL_UPDATE_PERSON_SUCCESS,
      actions.PARTIAL_UPDATE_PERSON_ERROR
    ],
    payload: { personID },
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().patch(`people/people/${personID}/`, {...formData}),
    successMessages: [{variant: "success", message: `${_.upperFirst(personType.split('_').join(' '))} has been updated`}],
  }
}

export const deletePerson = (personID,  personType, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.DELETE_PERSON_START,
      actions.DELETE_PERSON_SUCCESS,
      actions.DELETE_PERSON_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().delete(`people/people/${personID}/`),
    successMessages: [{variant: "success", message: `${_.upperFirst(personType.split('_').join(' '))} has been removed`}],
  }
}

export const getPartners = () => {
  return {
    types: [
      actions.GET_PARTNERS_START,
      actions.GET_PARTNERS_SUCCESS,
      actions.GET_PARTNERS_ERROR
    ],
    callAPI: () => authAxios().get('people/partners/'),
  }
}

export const linkPartner = (personID) => {
  return {
    types: [
      actions.LINK_PARTNER_START,
      actions.LINK_PARTNER_SUCCESS,
      actions.LINK_PARTNER_ERROR
    ],
    callAPI: () => authAxios().post('people/partners/', {partner_id: personID}),
  }
}

export const unlinkPartner = (personID) => {
  return {
    types: [
      actions.UNLINK_PARTNER_START,
      actions.UNLINK_PARTNER_SUCCESS,
      actions.UNLINK_PARTNER_ERROR
    ],
    callAPI: () => authAxios().delete(`people/partners/${personID}/`),
  }
}

export const getExecutors = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.GET_EXECUTORS_START,
      actions.GET_EXECUTORS_SUCCESS,
      actions.GET_EXECUTORS_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().get('people/executors/'),
  }
}

export const linkExecutor = (personID='', childID='', postSuccessHook = () => {}) => {
  return {
    types: [
      actions.LINK_EXECUTOR_START,
      actions.LINK_EXECUTOR_SUCCESS,
      actions.LINK_EXECUTOR_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('people/executors/', {person_id: personID, child_id: childID}),
  }
}

export const unlinkExecutor = (executorID, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.UNLINK_EXECUTOR_START,
      actions.UNLINK_EXECUTOR_SUCCESS,
      actions.UNLINK_EXECUTOR_ERROR
    ],
    postSuccessHook: postSuccessHook,
    payload: { executorID },
    callAPI: () => authAxios().delete(`people/executors/${executorID}/`),
  }
}

export const getChildren = () => {
  return {
    types: [
      actions.GET_CHILDREN_START,
      actions.GET_CHILDREN_SUCCESS,
      actions.GET_CHILDREN_ERROR
    ],
    callAPI: () => authAxios().get('people/children/'),
  }
}

export const createChild = (formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.CREATE_CHILD_START,
      actions.CREATE_CHILD_SUCCESS,
      actions.CREATE_CHILD_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('people/children/', {...formData}),
    successMessages: [{variant: "success", message: `Child has been added`}],
  }
}

export const deleteChild = (childID, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.DELETE_CHILD_START,
      actions.DELETE_CHILD_SUCCESS,
      actions.DELETE_CHILD_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().delete(`people/children/${childID}/`),
    successMessages: [{variant: "success", message: `Child has been removed`}],
  }
}

export const partialUpdateChild = (childID, formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.PARTIAL_UPDATE_CHILD_START,
      actions.PARTIAL_UPDATE_CHILD_SUCCESS,
      actions.PARTIAL_UPDATE_CHILD_ERROR
    ],
    payload: { childID },
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().patch(`people/children/${childID}/`, {...formData}),
    successMessages: [{variant: "success", message: `Child has been updated`}],
  }
}

export const deleteAllChildren = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.DELETE_ALL_CHILDREN_START,
      actions.DELETE_ALL_CHILDREN_SUCCESS,
      actions.DELETE_ALL_CHILDREN_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().delete('people/children/delete/all'),
  }
}

export const getGuardians = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.GET_GUARDIANS_START,
      actions.GET_GUARDIANS_SUCCESS,
      actions.GET_GUARDIANS_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().get('people/guardians/'),
  }
}


export const linkGuardian = (guardianID, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.LINK_GUARDIAN_START,
      actions.LINK_GUARDIAN_SUCCESS,
      actions.LINK_GUARDIAN_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('people/guardians/', {guardian_id: guardianID}),
  }
}

export const unlinkGuardian = (guardianID, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.UNLINK_GUARDIAN_START,
      actions.UNLINK_GUARDIAN_SUCCESS,
      actions.UNLINK_GUARDIAN_ERROR
    ],
    payload: { guardianID },
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().delete(`people/guardians/${guardianID}/`),
  }
}
