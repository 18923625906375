import { Hidden, IconButton } from "@material-ui/core";
import React from "react";
import { Send, Copy, X } from 'react-feather'
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification, dispatch) => {
  const key = notification.options && notification.options.key;

  return  {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
            options: {
        ...notification.options,
        autoHideDuration: 3000,
        action: key => (
          <Hidden smDown >
            <IconButton onClick={() => dispatch(closeSnackbar(key))} color="secondary"> <X size={20}/></IconButton>
          </Hidden>
        ),
        onClick: () => {
          dispatch(closeSnackbar(key));
        }
      }
    },
    }
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});
