import axios from 'axios'
import * as actions from 'redux/actions/config/types'
import authAxios from "utils/authAxios";

export const getConfig = () => {
  return {
    types: [
      actions.GET_CONFIG_START,
      actions.GET_CONFIG_SUCCESS,
      actions.GET_CONFIG_ERROR
    ],
    callAPI: () => authAxios().get('config/'),
  }
}

