import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { Phone, MessageCircle } from 'react-feather';
import Paper from "components/atoms/Paper/Paper";
import styled from "styled-components";
import member1 from 'images/member-1-thumb.png'
import member2 from 'images/member-2-thumb.png'
import member3 from 'images/member-3-thumb.png'
import { theme } from "theme/mainTheme";

const StyledContactBox = styled(Box)`

  
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
  
  .MuiTypography-root {
    margin-left: 20px;
    font-size: 1.8rem;
  }
`

const StyledThumbWrapper = styled.div`
  
  img {
    width: 60px;
    height: 60px;
    border: 2px #FFD327 solid ;
    border-radius: 2em;
    position: relative;
    margin-left: -5px;

  }
  
  .m1{ z-index: 3; }
  .m2{ z-index: 2; }
  .m3{ z-index: 1; }
  
  ${theme.breakpoints.up("lg")}{
    img {
      margin-bottom: -30px;
    }
  }
`

const ContactBox = () => {

  return (
    <Paper>
      <Grid  container>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Typography variant="subtitle1"><b>Speak to a team member</b></Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <StyledThumbWrapper>
            <img src={member1} className="m1" />
            <img src={member2} className="m2" />
            <img src={member3} className="m3" />
          </StyledThumbWrapper>
        </Grid>
        <Grid item xs={12} >
          <StyledContactBox display="flex" justifyContent="left"  >
            <MessageCircle/> <Typography className="font-secondary"><a href="javascript:void(Tawk_API.toggle())" className="MuiLink-root MuiLink-underlineHover">Live chat</a> with us</Typography>
          </StyledContactBox>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContactBox

