import React, { useContext } from 'react'
import _ from 'lodash'
import {
  Box,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Typography
} from "@material-ui/core";
import {Link as RouterLink, useHistory} from "react-router-dom";
import {NavigateNext, NavigateBefore } from "@material-ui/icons";
import styled from 'styled-components'
import { theme } from 'theme/mainTheme'
import { Helmet } from "react-helmet";


const YesNoButton = styled(Button)`

  padding: 10px;
  font-weight:bold;
  height: 120px;
  
  .MuiButton-label {
    margin-top: -20px;
    flex-direction: column;
    font-size: ${theme.typography.textSize.xLarge};
    font-family: ${theme.typography.fontFamily};
  }
  small {
    font-weight:normal;
    font-size: ${theme.typography.textSize.small};
    font-family: ${theme.typography.fontFamilySecondary};
    position: absolute;
    top: 60px;
    line-height: 20px;
    padding: 5px;
  }
  
`


const MultiStepContext = React.createContext({
  currentPageID: '',
  changePage: () => {}
})


const CustomPage = ({ pageTitle, eventName, children, pageID, nextPageID, nextButtonText, prevPageID, prevButtonText, image, bottomArea }) => {
  const { currentPageID, changePage, history } = useContext(MultiStepContext)

  return (
    currentPageID ===  pageID?
      <>
       <Helmet>
         <title>{pageTitle}</title>
         <meta property="og:title" content={pageTitle}/>
         <meta name="segment-event-name" content={eventName} />
       </Helmet>
       <Grid container direction="row" justify="center" alignItems="center" >
       <Grid item md={5} sm={10} xs={12} >
            <Box textAlign="center">
              {image ? <img src={image} /> : '' }
            </Box>
              <Box mt={1}>
                {children}
              </Box>
              <Box mt={3} mb={2}>
                {nextPageID?
                    <Button type="submit" onClick={() => changePage(nextPageID)} variant="contained" color="primary" fullWidth> {nextButtonText} </Button>
                  : null
                }
              </Box>
              <Grid container direction="row" justify="space-between" alignItems="center">
                {prevButtonText &&
                  <Box textAlign="center" display="flex" alignItems="center" justifyContent="center" >
                      <NavigateBefore color="action"/> <Link component={RouterLink} onClick={() => changePage(prevPageID || history.goBack())} > {prevButtonText}</Link>
                  </Box>
                }
                {bottomArea? bottomArea: ''}
              </Grid>
          </Grid>
        </Grid>
      </>
      : null
  )
}

const QuestionPage = ({ pageTitle, eventName, question, pageID, yesPageID, yesDesc, yesHandler, noPageID, noDesc, noHandler, prevPageID, image, prevButtonText='Previous question', noConfirmationModal = {} }) => {
  const { currentPageID, changePage } = useContext(MultiStepContext);
  const [noConfirmationOpen, setNoConfirmationOpen] = React.useState(false);

  const noConfirmationHandleClose = () => {
    setNoConfirmationOpen(false);
  };

  const storeQuestion = (answer) => {
    localStorage.setItem(`question: ${question}`, answer)
  }

  return (
    pageID === currentPageID?
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta property="og:title" content={pageTitle}/>
          <meta name="segment-event-name" content={eventName} />
        </Helmet>
        <Grid container direction="row" justify="center" alignItems="center" >

          <Grid item md={5} sm={10} xs={12} >
            <Box textAlign="center">
              {image ? <img src={image} /> : '' }
            </Box>
            <Box mt={1}>
              <Typography variant="h1" align="center"><Box fontWeight="fontWeightBold">{question}</Box></Typography>
            </Box>
            <Box mt={3} >
              <Grid container direction="row"  spacing={1}>
                <Grid item xs={6} >
                  <YesNoButton color="secondary" onClick={() => {changePage(yesPageID, yesHandler); storeQuestion('Yes')}} variant="contained" fullWidth>Yes <small>&nbsp;{yesDesc}</small></YesNoButton> &nbsp; &nbsp;
                </Grid>
                <Grid item xs={6}>
                  <YesNoButton color="secondary" onClick={() => {_.isEmpty(noConfirmationModal) ? changePage(noPageID, noHandler) : setNoConfirmationOpen(true); storeQuestion('No')}} variant="contained" fullWidth>No <small>&nbsp;{noDesc}</small></YesNoButton>
                </Grid>
              </Grid>
            </Box>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Box textAlign="center" display="flex" alignItems="center" justifyContent="center" >
                  <NavigateBefore color="action" /> <Link component={RouterLink} onClick={() => changePage(prevPageID)} > {prevButtonText}</Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {!_.isEmpty(noConfirmationModal) &&

          <Dialog open={noConfirmationOpen} onClose={noConfirmationHandleClose} >
            <DialogTitle >{noConfirmationModal['title']}</DialogTitle>
            <DialogContent>
              <DialogContentText className="font-secondary">
               {noConfirmationModal['body']}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {changePage(noPageID, noHandler); storeQuestion('No'); noConfirmationHandleClose()}} autoFocus>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        }
      </> : null
  )
}


const MultiStep = ({ children, currentPageID }) => {
  let history = useHistory();

  const changePage = (newPagePath, changeChandler) => {
  if(typeof changeChandler === "function") {
      changeChandler();
    }
    history.push(newPagePath);
  }

  return (
    <MultiStepContext.Provider value={{
      currentPageID,
      changePage,
      history
    }}>
      {children}
    </MultiStepContext.Provider>
  )
}

export { MultiStep, QuestionPage, CustomPage }
