import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useRef, useState } from "react";
import {
  getWills as getWillsAction,
  partialUpdateWill as partialUpdateWillAction,
  purchaseWill as purchaseWillAction
} from "redux/actions/wills";
import { connect } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import { theme } from 'theme/mainTheme'
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography
} from "@material-ui/core";
import { CheckSquare, CreditCard } from "react-feather";
import { push } from "connected-react-router";
import { routes } from "routes";
import segmentTrack from "utils/SegmentIO";

const StyledCardElement = styled(CardElement)`
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 15px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
`

const StyledSubmitButton = styled(Button) `
  background-color: ${theme.palette.success.main};
  width: 100%;
  color: white;
  &:hover{
    background-color: ${theme.palette.success.main};
  }
`

const CheckoutForm = ({user, will, getWills, purchaseWill, enqueueSnackbar}) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const SubmitRef = useRef(null);

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
  };

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  }

 const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true)
    const card = elements.getElement(CardElement);
    const {paymentMethod, error} = await stripe.createPaymentMethod({
     type: 'card',
     card: card
    });

    if(error){
      enqueueSnackbar({ message: error.message, options: { variant: "error"}})
      setProcessing(false)
    }else{

      await purchaseWill({'email': user.email, 'payment_method_id': paymentMethod.id },
        () => {
          setProcessing(false)
          enqueueSnackbar({ message: "Islamic will purchase successful", options: { variant: "success"}})
          segmentTrack("WPP | Purchased Islamic will")
          getWills()
        },
        () => {
          setProcessing(false)
        })
    }

  };

  return (
    <form  onSubmit={handleSubmit} className="stripe-form">

      <div className="form-row">
        <strong>Credit or debit card</strong>
        <StyledCardElement id="card-element" onChange={handleChange} options={CARD_ELEMENT_OPTIONS}/>
      </div>

      <Tooltip title={!user.is_active ? "You need to confirm your email address." : ""} >
        <span>
          <StyledSubmitButton ref={SubmitRef} type="submit" variant="contained" color="secondary" disableElevation startIcon={processing && <CircularProgress size={25}/> } disabled={processing || !user.is_active}>Confirm and pay &pound;{will.price / 100}</StyledSubmitButton>
        </span>
      </Tooltip>
    </form>
  );
};

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]
});


const mapDispatchToProps = dispatch => ({
  purchaseWill: (formData, postSuccessHook, postErrorHook) => dispatch(purchaseWillAction(formData, postSuccessHook, postErrorHook)),
  getWills: () => dispatch(getWillsAction()),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch)),
  redirectTo: (url) => dispatch(push(url)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutForm);

