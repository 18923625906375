import * as actions from 'redux/actions/wills/types'
import update from 'immutability-helper';

const initialState = {
  wills: {},
}

const willsReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_WILLS_START:
      return {...state}
    case actions.GET_WILLS_SUCCESS:
      return {...state, wills: action.data}
    case actions.GET_WILLS_ERROR:
      return {...state}

    case actions.PARTIAL_UPDATE_WILL_START:
      return {...state}
    case actions.PARTIAL_UPDATE_WILL_SUCCESS:
      return update(state, {
        wills: {
          [state.wills.findIndex(will => will.id === action.willID)] : {
            $set: action.data }
          }
      })
    case actions.PARTIAL_UPDATE_WILL_ERROR:
      return {...state}

    default:
      return state
  }
};

export default willsReducer

