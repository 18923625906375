import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import * as yup from 'yup'
import { connect } from 'react-redux';
import { passwordReset as passwordResetAction } from 'redux/actions/auth/passwordReset';
import { Form, Formik } from 'formik';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import TextField from "components/atoms/TextField/TextField";
import PrivacyNote from "components/molecules/PrivacyNote/PrivacyNote";
import segmentTrack from "utils/SegmentIO";


const PasswordReset = ({ passwordReset }) => {
  const formikRef = useRef(null);

  return (<>
    <Helmet>
      <title>Password reset</title>
      <meta property="og:title" content="Password reset"/>
      <meta name="segment-event-name" content="Log in | Password Reset | Viewed page" />
    </Helmet>
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item md={4} sm={8}>
        <Typography variant="h1" align="center">Password reset</Typography>
        <br/>
        <Formik
          innerRef={formikRef}
          initialValues={{
            email: '',
          }}
          validationSchema={
            yup.object().shape({
              email: yup.string()
                .label('Email')
                .required()
                .email(),
            })}

          onSubmit={(values, actions) => {
            passwordReset(values.email)
            segmentTrack("Log in | Password Reset | Submitted form", {
              'attempts': formikRef.current.submitCount
            })
            actions.resetForm({values: {
              email: ''
            }});
          }}
        >
          {({isSubmitting, touched, errors}) => {
            return (
              <Form>
                <div>
                  <TextField name="email" label="Email" fullWidth/>
                </div>
                <div>
                  <br/>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth> Send
                    password reset link</Button>
                </div>
              </Form>
            )
          }}
        </Formik>
        <Box mt={3} >
          <PrivacyNote />
        </Box>
      </Grid>
    </Grid>
  </>
  )
};

const mapDispatchToProps = dispatch => ({
  passwordReset: (email) => dispatch(passwordResetAction(email)),
});

export default connect(
  null,
  mapDispatchToProps,
)(PasswordReset);

