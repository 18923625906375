import React from 'react';
import _ from 'lodash'
import { Route, Redirect } from 'react-router-dom';
import isLogin from 'utils/isLogin';
import { routes } from 'routes'
import { connect } from "react-redux";

const WillEditRoute = ({wills, component: Component, ...rest}) => {
    return (
      <>
      {!isLogin() && <Redirect to={routes.login} />}

      {!_.isEmpty(wills) &&
         <Route {...rest} render={props => (
            wills[0].status !== 'reviewing' ?
                <Component {...props} />
            : <Redirect to={routes.will} />
        )} />
      }
      </>
    );
};

const mapStateToProps = ({ wills }) => ({
  wills: wills.wills
});

export default connect(
  mapStateToProps,
)(WillEditRoute);

