import * as actions from 'redux/actions/config/types'

const initialState = {

}

const configReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_CONFIG_START:
      return {...state}
    case actions.GET_CONFIG_SUCCESS:
      return {...state, ...action.data}
    case actions.GET_CONFIG_ERROR:
      return {...state}

    default:
      return state
  }
};

export default configReducer

