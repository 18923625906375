import { createGlobalStyle } from "styled-components";
import { theme } from "theme/mainTheme";

const GlobalStyle = createGlobalStyle`
    
    *, *::before, *::after {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    html {
      font-size: 62.5%;
      height: 100%;
    }
    
    body {
      height: 100%;
      font-size: 1.8rem;
      background-color: ${props => props.isLogin? '': '#f8f8f8'};
    }
    
    #root{
      height: 85%; 
    }
    
   .NavBar {
      background-color: ${props => props.isLogin? '': '#f8f8f8'};
      border-bottom-color: ${props => props.isLogin? '': '#f8f8f8'};
    }
    
    .MuiContainer-root{
      height: 100%;
      display: grid;
      grid-template-columns: 100%;
    }
    
    .font-primary{
      font-family: ${theme.typography.fontFamily};
    }
    
    .font-secondary{
      font-family: ${theme.typography.fontFamilySecondary};
    }
    
    .text-muted {
      color:  ${theme.palette.text.muted};
    }
    
    .underline{
      text-decoration: underline;
    }
`

export default GlobalStyle
