import * as actions from 'redux/actions/assets/types'

const initialState = {
  assets: [],
  shares: [],
  charities: []
}

const assetsReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_ASSETS_START:
      return {...state}
    case actions.GET_ASSETS_SUCCESS:
      return {...state, assets: action.data}
    case actions.GET_ASSETS_ERROR:
      return {...state}

    case actions.CREATE_ASSET_START:
      return {...state}
    case actions.CREATE_ASSET_SUCCESS:
      return {...state, assets: [...state.assets, action.data]}
    case actions.CREATE_ASSET_ERROR:
      return {...state}

    case actions.UPDATE_ASSET_START:
      return {...state}
    case actions.UPDATE_ASSET_SUCCESS:
      return {...state}
    case actions.UPDATE_ASSET_ERROR:
      return {...state}

    case actions.DELETE_ASSET_START:
      return {...state}
    case actions.DELETE_ASSET_SUCCESS:
      return {...state, assets: state.assets.filter(asset => asset.id !== action.assetID )}
    case actions.DELETE_ASSET_ERROR:
      return {...state}

    case actions.GET_ASSET_SHARES_START:
      return {...state}
    case actions.GET_ASSET_SHARES_SUCCESS:
      return {...state, shares: action.data}
    case actions.GET_ASSET_SHARES_ERROR:
      return {...state}

    case actions.UPDATE_ASSET_SHARES_START:
      return {...state}
    case actions.UPDATE_ASSET_SHARES_SUCCESS:
      return {...state, shares: action.data}
    case actions.UPDATE_ASSET_SHARES_ERROR:
      return {...state}

    case actions.DELETE_ASSET_SHARES_START:
      return {...state}
    case actions.DELETE_ASSET_SHARES_SUCCESS:
      return {...state, shares: action.data}
    case actions.DELETE_ASSET_SHARES_ERROR:
      return {...state}

    case actions.GET_CHARITIES_START:
      return {...state}
    case actions.GET_CHARITIES_SUCCESS:
      return {...state, charities: action.data}
    case actions.GET_CHARITIES_ERROR:
      return {...state}

    default:
      return state
  }
};

export default assetsReducer

