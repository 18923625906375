import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import { connect } from 'react-redux';
import TextField from "components/atoms/TextField/TextField";
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { routes } from "routes";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import { getMessage as getMessageAction, submitMessage as submitMessageAction } from "redux/actions/wasiyyah"
import SectionResolver from "utils/SectionsResolver";
import { sectionSlug, sectionSteps } from "views/will/Wassiya/Steps";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 2
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const helpText = "" +
  "<p>Leave a message or instructions for your loved ones. For example, include the Mosque you prefer your Janazah to be held in or the cemetery you wish to be buried in.</p>"

const Messages = ({ user, getMessage, message, submitMessage, getWills, will, partialUpdateWill }) => {

  useEffect(() => {
    getWills()
    getMessage()
  }, [])

  return (
    <>
    <Helmet>
      <title>{section["label"]}</title>
      <meta name="segment-event-name" content="MIW | Viewed wassiyah page" />
    </Helmet>
    <Grid container direction="row" spacing={5}>
            <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        {!_.isEmpty(message) &&
        <Formik
          initialValues={{
            message: message.message,
          }}
          enableReinitialize={true}
          onSubmit={async (values) => {
            await submitMessage(values)

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            getWills()
          }}
        >
          {({isSubmitting, touched, errors}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Add your message exactly as you wish it to appear</Typography>
                  </Box>
                  <TextField name="message" label="Optional" multiline rows={10} helpText="You can always update your Wasiyyah in the future." fullWidth/>
                </Box>
                <Box mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and
                    continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
        }
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wills, wasiyyah }) => ({
  user: account.user,
  formError: global.formError,
  will: wills.wills[0],
  message: wasiyyah.message
});

const mapDispatchToProps = dispatch => ({
  getWills: () => dispatch(getWillsAction()),
  getMessage: () => dispatch(getMessageAction()),
  submitMessage: (formData) => dispatch(submitMessageAction(formData)),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData, () => {} , routes.will))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
