import React from 'react';
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import TextField from "components/atoms/TextField/TextField";
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AboutYou/Steps";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SectionResolver from "utils/SectionsResolver";

const stepIndex = 1
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const AboutYouContact = ({ user, partialUpdateAccount, will, getWills, partialUpdateWill }) => {

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <Formik
          initialValues={{
            address_line_1: user.address_line_1,
            address_line_2: user.address_line_2,
            city: user.city,
            post_code: user.post_code,
            phone_number: user.phone_number,
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              address_line_1: yup.string()
                .label('Address')
                .required(),
              city: yup.string()
                .label('City')
                .required(),
              post_code: yup.string()
                .label('Post code')
                .required(),
              phone_number: yup.string().test('len', 'Phone number must be 11 digits', val => val !== undefined && val.length === 11)
            })}

          onSubmit={async (values) => {
            await partialUpdateAccount(values)

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            getWills()
          }}
        >
          {({isSubmitting, touched, errors}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your address?</Typography>
                  </Box>
                  <TextField name="address_line_1" label="Address line 1" fullWidth/>
                  <TextField name="address_line_2" label="Address line 2" fullWidth/>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <TextField name="city" label="City" fullWidth/> &nbsp;
                    <TextField name="post_code" label="Post code" fullWidth/>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Box mb={1}>
                    <Typography variant="h4">What is your contact number?</Typography>
                  </Box>
                  <TextField name="phone_number" type="string" label="Telephone number" helpText="We’ll only call you to help you with your will." fullWidth/>
                </Box>
                <Box mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Grid item sm={12} xs={12} md={4}>&nbsp;</Grid>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wills }) => ({
  user: account.user,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, nextSection["url"])),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  getWills: () => dispatch(getWillsAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutYouContact);
