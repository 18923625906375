import React, { useState } from 'react';
import _ from 'lodash'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, Grid,
  IconButton,
  Link,
  Switch,
  Typography
} from '@material-ui/core'
import { Plus, Save, Trash2, Map } from 'react-feather';
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextField from "components/atoms/TextField/TextField";
import { connect } from "react-redux";
import {
  createPerson as createPersonAction,
  deletePerson as deletePersonAction,
  partialUpdatePerson as partialUpdatePersonAction
} from "redux/actions/people";
import { birthDateJoin, birthDateSplit } from "utils/birthDateParser";
import styled from "styled-components";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import { getWills as getWillsAction } from "redux/actions/wills";
import { theme } from "theme/mainTheme";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import RadioField from "components/atoms/RadioField/RadioField";


const StyledDeleteButton = styled(Button)`
  margin-right: 10px;
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${theme.palette.grey.A200}
`

const StyledSwitch = styled(Switch) `
  .Mui-checked + .MuiSwitch-track{
    background-color: ${theme.palette.success.main};
  }
  
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 0.33;
  }
  
  .Mui-checked .MuiSwitch-thumb {
    background-color: ${theme.palette.success.main};
  }

`

const  AddPersonDialog = ({
    personType,
    dialogButtonText,
    submitButtonText,
    createPerson,
    user,
    person = null,
    partialUpdatePerson,
    deletePerson,
    getWills,
    textButton='',
    actionButtonRenderPersonType = true,
    linkPersonAction=() => {},
    unlinkPersonAction=() => {},
    enqueueSnackbar })  => {

  const [open, setOpen] = useState(false);
  const [useSameAddress, setUseSameAddress] = useState(false);

  const handleClickOpen = () => {
    setUseSameAddress(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const postSuccessHook = (payload) => {
    linkPersonAction(payload.data.id)
    handleClose();
  }

  const handleDelete = () => {
    deletePerson(person.id, personType, () => getWills())
    handleClose();
  }

  const sameAddressChange = (event, setFieldValue) => {

    if(event.target.checked){
      setUseSameAddress(true)
      setFieldValue('address_line_1', user.address_line_1)
      setFieldValue('address_line_2', user.address_line_2)
      setFieldValue('post_code', user.post_code)
      setFieldValue('city', user.city)
    }
    else{
      setFieldValue('address_line_1', '')
      setFieldValue('address_line_2', '')
      setFieldValue('post_code', '')
      setFieldValue('city', '')
      setUseSameAddress(false)
    }
  }

  return (
    <div>

      {textButton &&
        <Link href="#" onClick={handleClickOpen} cursor="pointer"> {textButton}</Link>
      }

      {!textButton &&
      <Button variant="contained" color="secondary" onClick={handleClickOpen} fullWidth> {dialogButtonText}
        <Plus size={20}/></Button>
      }

      <Dialog open={open} onClose={handleClose} fullWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h2">{person? "Edit" : "Add"} {personType.split('_').join(' ')}</Typography>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </StyledCloseButton>
        </DialogTitle>

          <Formik
            enableReinitialize
            initialValues={{
              full_name: person? person.full_name : '',
              email: person? person.email : '',
              over18: person ? person.over18 ? 'true' : 'false' : '',
              address_line_1: person? person.address_line_1 : '',
              address_line_2: person? person.address_line_2 : '',
              city: person? person.city : '',
              post_code: person? person.post_code : ''
            }}
            validationSchema={
              yup.object().shape({
                full_name: yup.string()
                .label('Full legal name')
                .required(),
                email: yup.string()
                  .label('Email')
                  .required()
                  .email(),
                over18: yup.string().label('Age').required(),
                address_line_1: yup.string()
                .label('Address')
                .required(),
                city: yup.string()
                  .label('City')
                  .required(),
                post_code: yup.string()
                  .label('Post code')
                  .required()
              })}


            onSubmit={(values,  { setSubmitting }) => {
              if(personType === 'guardian' || personType === 'executor_and_trustee'){

                if(values.over18 === 'false'){
                  enqueueSnackbar({
                    message: `${_.upperFirst(personType.split('_').join(' '))} must be over 18`,
                    options: {variant: "error"},
                   })
                   setSubmitting(false)
                   return false
                }
              }
              if(person) partialUpdatePerson(person.id, values, personType, postSuccessHook)
              else { createPerson(values, personType, postSuccessHook) }
            }}
          >
            {({isSubmitting, setFieldValue, touched, errors}) => {
              return (
                <DialogContent>
                <Form>
                  <TextField size="small" name="full_name" label="Full legal name" helpText="This is the name on the passport or driving license." fullWidth/>
                  <TextField size="small" name="email" label="Email" fullWidth />
                  <Box>
                    <Box mb={1}>
                      <Typography variant="h4">Age</Typography>
                    </Box>
                    <Box mb={2}>
                      <RadioField
                        name="over18"
                        values={{
                          "true": "Over 18",
                          "false": "Under 18",
                        }}
                        horizontal
                      />
                    </Box>

                  </Box>
                  <Box >
                    <Box mb={1} >
                      <Typography variant="h4">Address </Typography>
                      {!person &&
                        <> <StyledSwitch onChange={(e) => sameAddressChange(e, setFieldValue)} /> Use same address as mine </>
                      }
                    </Box>
                    {!useSameAddress &&
                      <Box>
                        <TextField size="small" name="address_line_1" label="Address line 1" fullWidth/>
                        <TextField size="small" name="address_line_2" label="Address line 2" fullWidth/>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <TextField size="small" name="city" label="City" fullWidth/> &nbsp;
                          <TextField size="small" name="post_code" label="Post code" fullWidth/>
                        </Box>
                      </Box>
                    }

                  </Box>
                  <Box mb={3} display="flex" justifyContent="space-between">
                    <Grid container spacing={1}>
                        {person &&
                          <Box clone order={{ xs: 2, sm: 1}}>
                            <Grid item xs={12} sm={6}  lg={6} >
                              <StyledDeleteButton variant="contained" color="secondary" startIcon={<Trash2 size={20}/>} onClick={() => handleDelete()} fullWidth>Remove {actionButtonRenderPersonType && personType}</StyledDeleteButton>
                            </Grid>
                          </Box>
                        }
                      <Box clone order={{ xs: 1, sm: 2 }}>
                        <Grid item xs={12} sm={person? 6 : 12} >
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} startIcon={<Save/>} fullWidth>Save {actionButtonRenderPersonType && personType}</Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Form>
                </DialogContent>
              )
            }}
          </Formik>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ account  }) => ({
  user: account.user,
});

const mapDispatchToProps = dispatch => ({
  createPerson: (formData, personType, postSuccessHook) => dispatch(createPersonAction(formData, personType, postSuccessHook)),
  partialUpdatePerson: (personID, formData, personType, postSuccessHook) => dispatch(partialUpdatePersonAction(personID, formData, personType, postSuccessHook)),
  deletePerson: (personID, personType, postSuccessHook) => dispatch(deletePersonAction(personID, personType, postSuccessHook)),
  getWills: () => dispatch(getWillsAction()),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPersonDialog);
