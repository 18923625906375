import React, { useState, useEffect }from "react";
import _ from 'lodash'
import { Link as RouterLink } from "react-router-dom";
import { Tab, Tabs} from "@material-ui/core";
import { Plus, List, User } from 'react-feather';
import styled from "styled-components";
import { routes } from 'routes'
import { theme } from 'theme/mainTheme'
import { connect } from "react-redux";


const StyledTabsWrapper = styled.div `
  margin-left: 40px;
`

const StyledTabs = styled(Tabs) `
  & .MuiTabs-indicator {
    height: 6px;
  }
`

const StyledTab = styled(Tab) `
  font-family: ${theme.typography.fontFamily};
  font-weight: normal;
  min-width: 0;
  opacity: 1;

  & .MuiTab-wrapper {
     flex-direction: row;
     
     & svg {
      margin-bottom: 2px !important;
     }
  }
`

const MainMenu = ({will, router}) => {

  const [ activeItem, setActiveItem] = useState(null)

  const indexMapping = { summary: 0, will: 1, invite: 2 }

  useEffect(() => {
    if (router.location.pathname.includes(routes.summary)) setActiveItem(indexMapping.summary)
    else if (router.location.pathname.includes(routes.will)) setActiveItem(indexMapping.will)
    else if (router.location.pathname.includes(routes.invite)) setActiveItem(indexMapping.invite)
    else setActiveItem(null)
  })

  return (
    <StyledTabsWrapper>
      <StyledTabs
      value={activeItem}
      indicatorColor="primary"
      >
        <StyledTab component={RouterLink} to={routes.summary}  icon={<List size={20}/>} label="Summary"/>
        <StyledTab component={RouterLink} to={routes.will}  icon={<User size={20}/>}  label="My Islamic will" disabled={process.env.REACT_APP_PRESS_RELEASE && ! _.isEmpty(will) && !will.paid }/>
        <StyledTab component={RouterLink} to={routes.invite} icon={<Plus size={20}/>}  label="Invite"/>
      </StyledTabs>
    </StyledTabsWrapper>
  )
}

const mapStateToProps = ({ wills, router }) => ({
  will: wills.wills[0],
  router
});

export default connect(
  mapStateToProps
)(MainMenu)
