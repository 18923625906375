import React from "react";
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import Paper from "components/atoms/Paper/Paper";
import TextField from "components/atoms/TextField/TextField";
import AddShareRecipientDialog from "components/molecules/AddShareRecipientDialog/AddShareRecipientDialog";
import styled from "styled-components";
import WillDownload from "components/molecules/WillDownload/WillDownload";
import { theme } from "theme/mainTheme";

import { Formik, Form, Field, FieldArray } from 'formik';

const StyledTextField = styled(TextField) `
  .MuiFormHelperText-root.Mui-error{
    display: none!important;
    
  }
  
  .Mui-error fieldset{
    border-color: #b00020!important;
  }
`

const ShareListItem = ({
    header,
    description = '',
    inputIndex,
    percentInputName,
    percentInputValue,
    percentInputDisabled= false,
    idInputValue,
    editButton = false,
    arrayField=false,
  }) => {

  return (
    <>
      <Paper >
        <div style={{ width: '100%' }}>
        <Grid container >
          <Grid xs={8}>
            <Typography variant="h4">{header}</Typography>
            {description &&
              <Box color="text.disabled">
                <span>{description}</span>
              </Box>
            }
            {editButton}
          </Grid>
            <Grid xs={4}>
                <StyledTextField
                  type="number"
                  name={arrayField? `${percentInputName}[${inputIndex}].percent` : percentInputName}
                  label="%"
                  disabled={percentInputDisabled}
                  defaultValue={percentInputValue}
                  fullWidth
                />

              {arrayField &&
                <Field type="hidden" name={`${percentInputName}[${inputIndex}].id`} value={idInputValue}/>
              }
            </Grid>
        </Grid>
        </div>
      </Paper>
    </>
  )
}


export default ShareListItem
