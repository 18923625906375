import React from "react";
import {Box, Typography} from "@material-ui/core";
import {LockOutlined} from "@material-ui/icons";
import styled from "styled-components";

const StyledCaptionWrapper = styled(Box)`
  line-height: normal;
  text-align: center;
`

const PrivacyNote = () => (
  <>
    <Box textAlign="center" mb={2} display="flex" alignItems="center" justifyContent="center">
      <LockOutlined /> &nbsp; &nbsp;<b>Privacy guaranteed</b>
    </Box>
    <StyledCaptionWrapper>
      <Typography variant="caption">Your privacy is taken seriously. Your data is never sold to third parties, and our world-class security ensures your will is entirely confidential.</Typography>
    </StyledCaptionWrapper>
 </>
)

export default PrivacyNote

