import * as actions from 'redux/actions/auth/types'

const initialState = {
}

const authReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.REGISTER_START:
      return {...state }
    case actions.REGISTER_SUCCESS:
      return {...state }
    case actions.REGISTER_ERROR:
      return {...state }

    case actions.LOGIN_START:
      return {...state }
    case actions.LOGIN_SUCCESS:
      return {...state , account: {...state.account, user: action.data}}
    case actions.LOGIN_ERROR:
      return {...state }
    case actions.LOGOUT_SUCCESS:
      return {...state, account: {...state.account, user: {}}}

    case actions.PASSWORD_RESET_START:
      return {...state }
    case actions.PASSWORD_RESET_SUCCESS:
      return {...state }
    case actions.PASSWORD_RESET_ERROR:
      return {...state }

    case actions.PASSWORD_RESET_CONFIRM_START:
      return {...state }
    case actions.PASSWORD_RESET_CONFIRM_SUCCESS:
      return {...state }
    case actions.PASSWORD_RESET_CONFIRM_ERROR:
      return {...state }
    default:
      return state
  }
};

export default authReducer
