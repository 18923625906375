import React from 'react';
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import SelectField from "components/atoms/SelectField/SelectField";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import CheckboxField from "components/atoms/CheckboxField/CheckboxField";
import Paper from "components/atoms/Paper/Paper";
import { routes } from "routes";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/AboutYou/Steps";
import SectionResolver from "utils/SectionsResolver";

const stepIndex = 4
const [sectionName, section] = SectionResolver(sectionSteps, stepIndex)

const Parents = ({ user, partialUpdateAccount, wills, partialUpdateWill, will, getWills }) => {

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container spacing={5} >
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} xs={12} md={4}>
        <Hidden mdUp>
          <Box mb={3}>
            <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.valid[sectionSlug]}/>
          </Box>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <Formik
          initialValues={{
            parents_details: user.parents_details,
            relatives_details: user.relatives_details
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              parents_details: yup.string()
                .label('Parents')
                .required(),
            })}

          onSubmit={async(values, { setSubmitting }) => {
            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            await partialUpdateAccount(values)

            getWills()
          }}
        >

          {({isSubmitting, touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Your parents details</Typography>
                  </Box>
                  <SelectField
                    name="parents_details"
                    label="Parents"
                    values={{
                      "both_alive": "Both alive",
                      "mother_alive": "Mother alive",
                      "father_alive": "Father alive",
                      "both_died": "Neither alive"
                    }}
                    fullWidth
                  />
                </Box>

                  <>
                    <Box mt={2} mb={1}>
                      <Typography variant="h4">Are these relatives still alive?</Typography>
                    </Box>
                    <Paper>
                        <CheckboxField
                          name="relatives_details"
                          values={{
                          "grandfather_farther_side": "Grandfather father side",
                          "grandmother_farther_side": "Grandmother father side",
                          "grandfather_mother_side": "Grandfather mother side",
                          "grandmother_mother_side": "Grandmother mother side",
                          }}
                        />
                    </Paper>
                  </>
                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Grid item sm={12} xs={12} md={4}>&nbsp;</Grid>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wills }) => ({
  user: account.user,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  partialUpdateAccount: (formData) => dispatch(partialUpdateAccountAction(formData, routes.aboutYouOtherRelatives)),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData)),
  getWills: () => dispatch(getWillsAction()),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Parents);
