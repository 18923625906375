import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/account/types'
import { unsetContentLoading } from "redux/actions/root";

export const getAccount = () => {
  return {
    types: [
      actions.GET_ACCOUNT_START,
      actions.GET_ACCOUNT_SUCCESS,
      actions.GET_ACCOUNT_ERROR
    ],
    callAPI: () => authAxios().get('account/'),
    postSuccessHook: (payload, dispatch) => {
      dispatch(unsetContentLoading())
    },
    errorMessages: [{variant: "error", message: "Couldn't fetch account data"}]
  }
}

export const partialUpdateAccount = (formData, redirectTo = false) => {
  return {
    types: [
      actions.PARTIAL_UPDATE_ACCOUNT_START,
      actions.PARTIAL_UPDATE_ACCOUNT_SUCCESS,
      actions.PARTIAL_UPDATE_ACCOUNT_ERROR
    ],
    redirectTo: redirectTo,
    callAPI: () => authAxios().patch('account/', {...formData}),
  }
}

export const deleteAccount = () => {
  return {
    types: [
      actions.DELETE_ACCOUNT_START,
      actions.DELETE_ACCOUNT_SUCCESS,
      actions.DELETE_ACCOUNT_ERROR
    ],
    callAPI: () => authAxios().delete('account/'),
  }
}


export const setActive = () => dispatch => {
  dispatch({ type: actions.SET_ACTIVE });
}


export const passwordSet = (formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.PASSWORD_SET_START,
      actions.PASSWORD_SET_SUCCESS,
      actions.PASSWORD_SET_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('auth/users/set_password/',{...formData}),
    successMessages: [{ variant: "success", message: "Your password has been updated" }],
  }
}



