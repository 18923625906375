import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "components/molecules/CheckoutForm/CheckoutForm";

const Checkout = () => {

  const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  return (
    <Elements stripe={stripe}>
      <CheckoutForm />
    </Elements>
  )

};


export default Checkout
