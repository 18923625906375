import { routes } from 'routes'


export const sectionSlug = 'wassiya'

export const sectionSteps = {
  "charity": {
    "label": "Charity",
    "url": routes.wassiyaCharity,
  },
  "gifts": {
    "label": "Gifts",
    "url": routes.wassiyaGifts,
  },
  "messages": {
    "label": "Messages",
    "url": routes.wassiyaMessages,
  }
}
