import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/invite/types'

export const invite = (formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.INVITE_START,
      actions.INVITE_SUCCESS,
      actions.INVITE_ERROR
    ],
    callAPI: () => authAxios().post('invite/', {...formData}),
    postSuccessHook: postSuccessHook,
    successMessages: [{variant: "success", message: "User has been invited."}]
  }
}




