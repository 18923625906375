import React from "react";
import { Helmet } from "react-helmet";
import * as yup from 'yup'
import { connect } from 'react-redux';
import { activationResend as activationResendAction } from 'redux/actions/auth/Activation';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, CircularProgress } from '@material-ui/core';


const ActivationResend = ({activationResend}) => {

  return (<>
    <Helmet>
      <title>Resend Activation</title>
    </Helmet>
    <h1>Resend Activation Form</h1>
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema= {
        yup.object().shape({
        email: yup.string()
          .label('Email')
          .required()
          .email(),
      })}

      onSubmit={async ( values) => {
        await activationResend(values.email)
      }}
    >
      {({ isSubmitting, touched, errors }) => {
        return (
          <Form >
            <div>
              <Field component={TextField} id="email" name="email" label="Email" />
            </div>
            <div>
              <br/>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} > Resend Activation Email  {isSubmitting? <CircularProgress size={15} /> : '' }</Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  </>

)};


const mapStateToProps = ({ globalError }) => ({
  globalError
});

const mapDispatchToProps = dispatch => ({
  activationResend: (email) => dispatch(activationResendAction(email)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivationResend);

