import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/assets/types'

export const getAssets = () => {
  return {
    types: [
      actions.GET_ASSETS_START,
      actions.GET_ASSETS_SUCCESS,
      actions.GET_ASSETS_ERROR
    ],
    callAPI: () => authAxios().get('assets/assets/'),
  }
}

export const createAsset = (formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.CREATE_ASSET_START,
      actions.CREATE_ASSET_SUCCESS,
      actions.CREATE_ASSET_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('assets/assets/', {...formData}),
    successMessages: [{variant: "success", message: "Asset has been added successfully"}],
  }
}

export const updateAsset = (assetID, formData, postSuccessHook = () => {}) => {
  return {
    types: [
      actions.UPDATE_ASSET_START,
      actions.UPDATE_ASSET_SUCCESS,
      actions.UPDATE_ASSET_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().put(`assets/assets/${assetID}/`, {...formData}),
  }
}

export const deleteAsset = (assetID) => {
  return {
    types: [
      actions.DELETE_ASSET_START,
      actions.DELETE_ASSET_SUCCESS,
      actions.DELETE_ASSET_ERROR
    ],
    payload: { assetID },
    callAPI: () => authAxios().delete(`assets/assets/${assetID}/`),
  }
}

export const getAssetShares = (assetID) => {
  return {
    types: [
      actions.GET_ASSET_SHARES_START,
      actions.GET_ASSET_SHARES_SUCCESS,
      actions.GET_ASSET_SHARES_ERROR
    ],
    callAPI: () => authAxios().get(`assets/assets/${assetID}/shares/`),
  }
}

export const createAssetShares = (assetID, formData) => {
  return {
    types: [
      actions.CREATE_ASSET_SHARES_START,
      actions.CREATE_ASSET_SHARES_SUCCESS,
      actions.CREATE_ASSET_SHARES_ERROR
    ],
    callAPI: () => authAxios().post(`assets/assets/${assetID}/shares/`, {...formData}),
  }
}

export const updateAssetShares = (assetID, shareID, formData) => {
  return {
    types: [
      actions.UPDATE_ASSET_SHARES_START,
      actions.UPDATE_ASSET_SHARES_SUCCESS,
      actions.UPDATE_ASSET_SHARES_ERROR
    ],
    callAPI: () => authAxios().patch(`assets/assets/${assetID}/shares/${shareID}/`, {...formData}),
  }
}

export const deleteAssetShares = (assetID, shareID, formData) => {
  return {
    types: [
      actions.DELETE_ASSET_SHARES_START,
      actions.DELETE_ASSET_SHARES_SUCCESS,
      actions.DELETE_ASSET_SHARES_ERROR
    ],
    callAPI: () => authAxios().delete(`assets/assets/${assetID}/shares/${shareID}/`),
  }
}

export const getCharities = () => {
  return {
    types: [
      actions.GET_CHARITIES_START,
      actions.GET_CHARITIES_SUCCESS,
      actions.GET_CHARITIES_ERROR
    ],
    callAPI: () => authAxios().get('assets/charities/'),
  }
}
