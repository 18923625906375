import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { connect } from 'react-redux'
import Paper from "components/atoms/Paper/Paper";
import { DeleteForeverOutlined } from "@material-ui/icons";
import { deleteAsset as deleteAssetAction } from "redux/actions/assets";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";


const AssetBox = ({asset,  deleteAsset}) => {


  const handleDeleteAsset = (event) => {
     deleteAsset(asset.id)
  }

  return (
    <>
      <Paper >
        <Box display="flex" alignItems="center" mb={1}>
          <Box flexGrow={1}>
            <Typography variant="h4">{asset.asset_name}</Typography>
            <Box color="text.disabled">
              {asset.asset === 'bank_account' && asset.name}
              {asset.asset === 'business' && asset.name}
              {asset.asset === 'pension' && asset.provider}
              {asset.asset === 'life_insurance' && asset.provider}
              {asset.asset === 'stocks_and_shares' && asset.provider}
              {asset.asset === 'property' && asset.address_line_1}
              {asset.asset === 'gold_and_silver' &&
                <>
                {asset.gold && <>Gold: {asset.gold} gram(s)<br/></> }
                {asset.silver && <>Silver: {asset.silver} gram(s)</>}
                </>
              }
            </Box>
          </Box>

          {/*<AddAssetDialog dialogButtonText="Edit asset" iconButton  />*/}
          <Tooltip title="Delete asset">
            <IconButton component="span">
              <DeleteForeverOutlined onClick={handleDeleteAsset}/>
            </IconButton>
          </Tooltip>
          <Box>
          </Box>
        </Box>

      </Paper>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteAsset: (assetID) => dispatch(deleteAssetAction(assetID)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  null,
  mapDispatchToProps
)(AssetBox)
