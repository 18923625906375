import React from "react";
import styled, { css }from "styled-components";
import { Paper as MUIPaper } from "@material-ui/core";
import { theme } from 'theme/mainTheme'


const StyledPaper = styled(MUIPaper)`
  padding: 20px 20px;
  border: 2px ${theme.palette.grey["300"]} solid;
  
  ${({ active }) => (
    active && css`
      border-color: ${props => props.active? theme.palette.primary.main: theme.palette.grey["300"]};
      border-width: ${props => props.active? '4px': '2px'};
    ` 
  )}
`

const Paper = ({children, active, ...props}) => (
  <StyledPaper active={active} elevation={active? 4 : 0} {...props} >{children}</StyledPaper>
)

export default Paper



