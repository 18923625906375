import _ from "lodash";
import { push } from 'connected-react-router'
import { enqueueSnackbar } from "redux/actions/snackbar";
import {setGlobalFormError} from "../redux/actions/root";


function callAPIMiddleware({ dispatch, getState }) {
  return next => action => {
    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
      payload = {},
      redirectTo = false,
      successMessages = [],
      errorMessages = [],
      errorMessagesResponseKeys = [],
      postSuccessHook = () => {},
      postErrorHook = () => {}
    } = action

    if (!types) {
      return next(action)
    }

    if (
      !Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')
    ) {
      throw new Error('Expected an array of three string types.')
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.')
    }

    if (!shouldCallAPI(getState())) {
      return
    }

    const [requestType, successType, failureType] = types

    dispatch(
      Object.assign({}, payload, {
        type: requestType
      })
    )

    return callAPI()
      .then(response => {
        dispatch(
          Object.assign({}, payload, {
            data: response.data,
            type: successType
          })
        )

        postSuccessHook(response, dispatch);

        successMessages.forEach((item) => {
          dispatch(enqueueSnackbar({
            message: item.message,
            options: {variant: item.variant},
          }, dispatch))

        })

        if (redirectTo) dispatch(push(redirectTo))

      }).catch( error => {
        dispatch(
          Object.assign({}, payload, {
            error,
            type: failureType
          })
        )

        postErrorHook(error, dispatch);

        let _errorMessages = errorMessages
        if (errorMessages !== false){

          if(_.isEmpty(_errorMessages)){

            if(error.response) {
              if(error.config.method === 'get') {
                _errorMessages.push({ variant: "error", message: `${error.response.status}: ${error.response.statusText}` })
              } else {
                dispatch(setGlobalFormError(error)) // set global form error if not GET method
                _errorMessages = [] // mute if error in response, it's usually form error, unless specified otherwise
                errorMessagesResponseKeys.forEach((key) => {
                  let message = _.get(error, `response.${key}`)
                  if(!_.isEmpty(message)) _errorMessages.push({variant: "error", message: message })
                });
              }

            } else {
              _errorMessages.push({ variant: "error", message: error.message })
            }
          }

          _errorMessages.forEach((item) => {
            dispatch(enqueueSnackbar({
                message: item.message,
                options: {variant: item.variant},
            }, dispatch))
          })

        }
      }
    )
  }
}

export default callAPIMiddleware
