import { routes } from 'routes'


export const sectionSlug = 'accounts_and_property'

export const sectionSteps = {
  "accounts-and-property": {
    "label": "Accounts and property",
    "url": routes.accountAndProperty,
  },
}
