import isLogin from "utils/isLogin";
import authAxios from "utils/authAxios";


const segmentTrack = (eventName, ...args) => {
  let location = window.location.pathname
  let referrer = document.referrer;
  if(isLogin()){
    authAxios().get('account/segmentio')
			.then(response => {
        window.analytics.track(
          eventName,
          {'entry_point': location, 'referrer': referrer, ...response.data, ...args[0]})
		});
  }else{
     window.analytics.track(eventName, {'entry_point': location, 'referrer': referrer, ...args[0]})
  }
}

export default segmentTrack
