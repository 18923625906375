import React from 'react'
import _ from "lodash";
import { useEffect } from "react";
import { connect } from 'react-redux';
import { getAccount as getAccountAction } from "redux/actions/account";
import { setContentLoading as setContentLoadingAction, unsetContentLoading as unsetContentLoadingAction } from "redux/actions/root";
import isLogin from "./isLogin";
import { getWills as getWillsAction } from "redux/actions/wills";
import { Helmet } from "react-helmet";


const AppProvider = ({children, user, getAccount, wills, getWills, metaTags, getMetaTags, setContentLoading, unsetContentLoading}) => {

  useEffect(() => {

    if(isLogin() && (_.isEmpty(user) || _.isEmpty(wills))){
      setContentLoading()
      getAccount()
      getWills()
    }else{
      unsetContentLoading()
    }
  })
  return (<>{children}</>)
}

const mapStateToProps = ({ account, wills, seo }) => ({
  user: account.user,
  wills: wills.wills,
});


const mapDispatchToProps = dispatch => ({
  getAccount: () => dispatch(getAccountAction()),
  getWills: () => dispatch(getWillsAction()),
  setContentLoading: () => dispatch(setContentLoadingAction()),
  unsetContentLoading: () => dispatch(unsetContentLoadingAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppProvider);
