import React, { useEffect } from 'react';
import _ from 'lodash'
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import {
  deleteCharityShares as deleteCharitySharesAction,
  getCharities as getCharitiesAction,
  getCharityShares as getCharitySharesAction,
  submitCharityShares as submitCharitySharesAction
} from "redux/actions/wasiyyah";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import RadioField from "components/atoms/RadioField/RadioField";
import TextField from "components/atoms/TextField/TextField";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/Wassiya/Steps";
import SectionResolver from "utils/SectionsResolver";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 0
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const WasiyyahCharity = ({
     user,
     charities,
     getCharities,
     charityShares,
     getCharityShares,
     submitCharityShares,
     deleteCharityShares,
     will,
     getWills,
     partialUpdateWill
  }) => {

  useEffect(() => {
    getCharities()
    getCharityShares()

  }, [])

  const addCharityInit = () => {
    if(!will.progress.wassiya.charity) return "yes"
    else return _.isEmpty(charityShares)? "no" : "yes"
  }

  const defaultCharity = () => {
    let output
    charities.map(charity => {
      if(charity.id === charityShares.charity) {
        output = charity
      }
    })
    return output
  }

  const helpText = "" +
    "<p class='font-primary'><b>How much wealth can I leave to a charity?</b></p>" +
    "<p>As a Muslim, you can leave up to 33% of your total wealth to those who aren't entitled to inherit from you.</p>" +
    "<p>Including a charity in your Islamic will is a great opportunity to earn Sadaqah Jariyah, a continuous and ongoing charity after you die.</p>"

  return (
    <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        <Formik
          initialValues={{
            include_charity: addCharityInit(),
            charity: defaultCharity(),
          }}
          enableReinitialize={true}
          validationSchema={
            yup.object().shape({
              include_charity: yup.string()
                .label('Charity')
                .required(),
              charity: yup.string().when('include_charity', {
                  is: "yes",
                  then: yup.string().label('Charity').nullable().required()
              }),
            })}

          onSubmit={async (values, { setSubmitting }) => {

            if(values.include_charity === 'no'){
              deleteCharityShares()
            }else{
              values["charity"] = values['charity']['id']
              await submitCharityShares(values)
            }

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})

            getWills()
          }}
        >

          {({isSubmitting, setFieldValue,touched, errors, values}) => {
            return (
              <Form>
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Would you like to leave a share of your wealth to a charity?</Typography>
                  </Box>
                    <RadioField
                      name="include_charity"
                      values={{
                        "yes": "Yes",
                        "no": "No",
                      }}
                      horizontal
                    />

                  { values.include_charity === 'yes' && (
                    <Box mt={2}>
                      <Box mb={1}>
                        <Typography variant="h4">Who would you like to leave it to?</Typography>
                      </Box>

                      {!_.isEmpty(charities) && !_.isEmpty(user) && <Autocomplete
                        id="charity"
                        ListboxProps={{ style: { maxHeight: '20rem' } }}
                        options={charities}
                        height={100}
                        getOptionLabel={(option) => option.full_name}
                        defaultValue={defaultCharity()}
                        onChange={(e, value) => {
                          setFieldValue("charity", value);
                        }}
                        renderInput={(params) => <TextField {...params} label="Charity" name="charity" variant="outlined" helpText="Can’t find the charity you’re looking for? Contact info@truewills.co.uk" /> }

                      />}
                    </Box>
                  )}

                </Box>

                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>
  )
};

const mapStateToProps = ({ account, global, wasiyyah, wills }) => ({
  user: account.user,
  charities: wasiyyah.charities,
  charityShares: wasiyyah.charity_shares,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  getCharities: () => dispatch(getCharitiesAction()),
  getCharityShares: () => dispatch(getCharitySharesAction()),
  getWills: () => dispatch(getWillsAction()),
  submitCharityShares: (formData) => dispatch(submitCharitySharesAction(formData)),
  deleteCharityShares: () => dispatch(deleteCharitySharesAction()),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData, () => {}, nextSection["url"])),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WasiyyahCharity);
