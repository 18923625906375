import * as actions from "redux/actions/auth/types";
import axios from "axios";
import { go } from "connected-react-router";
import { routes } from "routes";
import { getAccount } from "redux/actions/account";
import authAxios from "utils/authAxios";


export const login = (email, password) => {
  return {
    types: [
      actions.LOGIN_START,
      actions.LOGIN_SUCCESS,
      actions.LOGIN_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/jwt/create/`,{ email, password }),
    successMessages: [{variant: "success", message: "Login Successful"}],
    redirectTo: routes.referred,
    postSuccessHook: (payload, dispatch) => {
      localStorage.setItem('JWTAccessToken', payload.data.access)
      localStorage.setItem('JWTRefreshToken', payload.data.refresh)
      dispatch(getAccount())

      authAxios().get('account/').then(response => {
          window.analytics.identify(response.data.id, {
            user_is_staff: response.data.email.includes("@truewills.co.uk")
          });
      })

    },
    errorMessages: [{variant: "error", message: "Email or password incorrect"}],
  }
}


export const logout = () => dispatch => {
  localStorage.clear()
  dispatch({ type: actions.LOGOUT_SUCCESS });
  dispatch(go(routes.home))
}
