import * as actions from "redux/actions/auth/types";
import axios from 'axios'
import { routes } from "routes";

export const passwordReset = (email) => {
  return {
    types: [
      actions.PASSWORD_RESET_START,
      actions.PASSWORD_RESET_SUCCESS,
      actions.PASSWORD_RESET_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/reset_password/`,{ email }),
    successMessages: [{ variant: "success", message: "Password reset email has been sent" }],
  }
}


export const passwordResetConfirm = (uid, token, password) => {
  return {
    types: [
      actions.PASSWORD_RESET_CONFIRM_START,
      actions.PASSWORD_RESET_CONFIRM_SUCCESS,
      actions.PASSWORD_RESET_CONFIRM_ERROR
    ],
    callAPI: () => axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/users/reset_password_confirm/`,{ uid, token, new_password: password }),
    successMessages: [{ variant: "success", message: "Your password has been reset successfully" }],
    redirectTo: routes.login,
    errorMessagesResponseKeys: ['data.token', 'data.uid'],
  }
}
