export const GET_ACCOUNT_START = 'GET_ACCOUNT_START';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const PARTIAL_UPDATE_ACCOUNT_START = 'PARTIAL_UPDATE_ACCOUNT_START';
export const PARTIAL_UPDATE_ACCOUNT_SUCCESS = 'PARTIAL_UPDATE_ACCOUNT_SUCCESS';
export const PARTIAL_UPDATE_ACCOUNT_ERROR = 'PARTIAL_UPDATE_ACCOUNT_ERROR';

export const DELETE_ACCOUNT_START = 'DELETE_ACCOUNT_START';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_SECTION_COMPLETE = 'SET_SECTION_COMPLETE';

export const PASSWORD_SET_START = 'PASSWORD_SET_START'
export const PASSWORD_SET_SUCCESS = 'PASSWORD_SET_SUCCESS'
export const PASSWORD_SET_ERROR = 'ACTIVATION_RESEND_ERROR'
