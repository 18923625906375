import React, { useState } from 'react';
import _ from 'lodash'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle, Grid,
  IconButton,
  Link,
  Switch,
  Typography
} from '@material-ui/core'
import { Plus, Save, Trash2, Map } from 'react-feather';
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextField from "components/atoms/TextField/TextField";
import { connect } from "react-redux";
import {
  createRecipientShares as createRecipientAction,
  deleteRecipientShare as deleteRecipientAction,
  partialUpdateRecipientShares as partialUpdateRecipientAction
} from "redux/actions/wasiyyah";
import { birthDateJoin, birthDateSplit } from "utils/birthDateParser";
import styled from "styled-components";
import { enqueueSnackbar as enqueueSnackbarAction } from "redux/actions/snackbar";
import { getWills as getWillsAction } from "redux/actions/wills";
import { theme } from "theme/mainTheme";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "routes";
import RadioField from "components/atoms/RadioField/RadioField";


const StyledDeleteButton = styled(Button)`
  margin-right: 10px;
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${theme.palette.grey.A200}
`

const AddShareRecipientDialog = ({
    dialogButtonText,
    createRecipient,
    recipient = null,
    partialUpdateRecipient,
    deleteRecipient,
    textButton='',
    postCreateHook = (data) => {},
    postDeleteHook = (id) => {},
    enqueueSnackbar })  => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = (data) => {
      postCreateHook(data)
      handleClose();
  }

  const handleUpdate = () => {
    handleClose();
  }

  const handleDelete = () => {
    deleteRecipient(recipient.id)
    postDeleteHook(recipient.id);
    handleClose();
  }

  return (
    <div>

      {textButton &&
        <Link href="#" onClick={handleClickOpen} cursor="pointer"> {textButton}</Link>
      }

      {!textButton &&
      <Button variant="contained" color="secondary" onClick={handleClickOpen} fullWidth> {dialogButtonText}
        <Plus size={20}/></Button>
      }


      <Dialog open={open} onClose={handleClose} fullWidth={"sm"} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h2">{recipient? "Edit" : "Add"} recipient</Typography>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </StyledCloseButton>
        </DialogTitle>

          <Formik
            enableReinitialize
            initialValues={{
              full_name: recipient? recipient.full_name : '',
              relationship: recipient? recipient.relationship : '',
            }}
            validationSchema={
              yup.object().shape({
                full_name: yup.string()
                .label('Full name')
                .required(),
                relationship: yup.string()
                  .label('Relationship')
                  .required(),
              })}

            onSubmit={(values,  { setSubmitting }) => {
              if(recipient) partialUpdateRecipient(recipient.id, values, handleUpdate)
              else createRecipient(values, handleCreate)
            }}
          >
            {({isSubmitting, setFieldValue, touched, errors}) => {
              return (
                <DialogContent>
                <Form>
                  <TextField size="small" name="full_name" label="Full legal name" helpText="This is the name on the passport or driving license." fullWidth/>
                  <TextField size="small" name="relationship" label="Relationship" fullWidth />

                  <Box mb={3} display="flex" justifyContent="space-between">
                    <Grid container spacing={1}>
                        {recipient &&
                          <Box clone order={{ xs: 2, sm: 1}}>
                            <Grid item xs={12} sm={6}  lg={6} >
                              <StyledDeleteButton variant="contained" color="secondary" startIcon={<Trash2 size={20}/>} onClick={() => handleDelete()} fullWidth>Remove recipient</StyledDeleteButton>
                            </Grid>
                          </Box>
                        }
                      <Box clone order={{ xs: 1, sm: 2 }}>
                        <Grid item xs={12} sm={recipient? 6 : 12} >
                            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} startIcon={<Save/>} fullWidth>Save recipient</Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Form>
                </DialogContent>
              )
            }}
          </Formik>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ account  }) => ({
  user: account.user,
});

const mapDispatchToProps = dispatch => ({
  createRecipient: (formData, postSuccessHook) => dispatch(createRecipientAction(formData, postSuccessHook)),
  partialUpdateRecipient: (personID, formData, postSuccessHook) => dispatch(partialUpdateRecipientAction(personID, formData, postSuccessHook)),
  deleteRecipient: (personID, postSuccessHook) => dispatch(deleteRecipientAction(personID, postSuccessHook)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddShareRecipientDialog);
