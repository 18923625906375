export const GET_ALERTS_START = 'GET_ALERTS_START';
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS';
export const GET_ALERTS_ERROR = 'GET_ALERTS_ERROR';

export const GET_SUBSCRIBED_ALERT_START = 'GET_SUBSCRIBED_ALERT_START';
export const GET_SUBSCRIBED_ALERT_SUCCESS = 'GET_SUBSCRIBED_ALERT_SUCCESS';
export const GET_SUBSCRIBED_ALERT_ERROR = 'GET_SUBSCRIBED_ALERT_ERROR';

export const SUBSCRIBE_ALERT_START = 'SUBSCRIBE_ALERT_START';
export const SUBSCRIBE_ALERT_SUCCESS = 'SUBSCRIBE_ALERT_SUCCESS';
export const SUBSCRIBE_ALERT_ERROR = 'SUBSCRIBE_ALERT_ERROR';

export const UNSUBSCRIBE_ALERT_START = 'UNSUBSCRIBE_ALERT_START';
export const UNSUBSCRIBE_ALERT_SUCCESS = 'UNSUBSCRIBE_ALERT_SUCCESS';
export const UNSUBSCRIBE_ALERT_ERROR = 'UNSUBSCRIBE_ALERT_ERROR';

