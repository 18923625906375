import React, { useEffect } from 'react';
import { routes } from "routes";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

const Home = ({ router }) => {

  useEffect(() => {
    if(router.location.query.ref){
      localStorage.setItem('refID', router.location.query.ref)
    }
  }, [])

  return (
    <>
      <Helmet><title></title></Helmet>
      <Redirect to={routes.start}/>
    </>
  )
};

const mapStateToProps = ({ router }) => ({
  router
});

export default connect(
  mapStateToProps,
)(Home);


