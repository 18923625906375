import { routes } from 'routes'


export const sectionSlug = 'about_yourself'

export const sectionSteps = {
  "names": {
    "url": routes.aboutYouNames,
    "label": "A bit about you"
  },
  "contact": {
    "label": "Your contact details",
    "url": routes.aboutYouContact,
  },
  "marital-status": {
    "label":"Your marital status",
    "url": routes.aboutYouMaritalStatus,
  },
  "children": {
    "label": "Your children",
    "url": routes.aboutYouChildren,
  },
  "parents": {
    "label": "Your parents",
    "url": routes.aboutYouParents,
  },
  "other-relatives": {
    "label": "Other relatives",
    "url": routes.aboutYouOtherRelatives,
  }
}
