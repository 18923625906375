import * as actions from 'redux/actions/alerts/types'

const initialState = {
  all: {},
  subscribed: {}
}

const alertsReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_ALERTS_START:
      return {...state}
    case actions.GET_ALERTS_SUCCESS:
      return {...state, all: action.data}
    case actions.GET_ALERTS_ERROR:
      return {...state}

    case actions.GET_SUBSCRIBED_ALERT_START:
      return {...state}
    case actions.GET_SUBSCRIBED_ALERT_SUCCESS:
      return {...state, subscribed: action.data}
    case actions.GET_SUBSCRIBED_ALERT_ERROR:
      return {...state}

    case actions.SUBSCRIBE_ALERT_START:
      return {...state}
    case actions.SUBSCRIBE_ALERT_SUCCESS:
      return {...state}
    case actions.SUBSCRIBE_ALERT_ERROR:
      return {...state}

    case actions.UNSUBSCRIBE_ALERT_START:
      return {...state }
    case actions.UNSUBSCRIBE_ALERT_SUCCESS:
      return {...state}
    case actions.UNSUBSCRIBE_ALERT_ERROR:
      return {...state}

    default:
      return state
  }
};

export default alertsReducer

