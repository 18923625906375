import React from "react";
import { Box, Button, Checkbox, Typography, Link } from "@material-ui/core";
import Paper from "components/atoms/Paper/Paper";
import { Edit } from "@material-ui/icons";
import AddPersonDialog from "components/molecules/AddPersonDialog/AddPersonDialog";

const PersonListItem = ({person, personType, selectable=false, checked, dialogActionButtonRenderPersonType= true,  onCheckedHook = () => {} , onUncheckedHook = () => {}}) => {

  const clickHandler = (e) => {
    if(e.target.checked) onCheckedHook(person.id)
    else  onUncheckedHook(person.id)
  }

  return (
    <>
      <Paper>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4">{person.full_name}</Typography>
            <Box color="text.disabled">
              <div style={{overflow: "hidden", textOverflow: "ellipsis"}}>
              <Typography >{person.email}</Typography>
              </div>
            </Box>
            <AddPersonDialog
              personType={personType}
              textButton="Edit"
              person={person}
              actionButtonRenderPersonType={dialogActionButtonRenderPersonType}
            />
          </Box>
          {selectable &&
            <Box>
              <Checkbox name="subscribe" color="primary" defaultChecked={checked} onClick={(event) => clickHandler(event)}/>
            </Box>
          }
        </Box>
      </Paper>
    </>
  )
}


export default PersonListItem
