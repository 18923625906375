import React from 'react';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import styled from "styled-components";
import Stepper from "@material-ui/core/Stepper";
import { theme } from "theme/mainTheme";
import { useMediaQuery } from "@material-ui/core";
import classNames from 'classnames/bind';

const StyledStepper = styled(Stepper) `
  background-color: transparent;
  
  .MuiStep-root{
    margin-bottom: 8px;
  }
  
  .MuiStepLabel-label {
    font-size: 1.8rem;
    text-align: left;
  }
  
  .MuiStepLabel-completed{
    font-weight: normal;
  }
  
  .MuiStepLabel-active{
    font-weight: 500;
  }
  
  .MuiStepIcon-completed {
    color: ${theme.palette.success.main}
  }
  
  .mobile .MuiStepIcon-active{
    color: ${theme.palette.primary.main}
  }

`


function WillSectionStepper({ steps, valid, activeStepIndex, redirectTo }) {
  const smUp = !useMediaQuery(theme.breakpoints.down("sm"));

  const handleStep = (step) => () => {
    redirectTo(steps[step]['url']);
  };

  return (
    <>
      <StyledStepper nonLinear activeStep={activeStepIndex} orientation={smUp? "vertical": "horizontal"} style={{marginTop: smUp? '70px': '0', padding: smUp? '24px': '0'}}>
        {Object.keys(steps).map((keyName, i) => (
          <Step key={keyName} >
            <StepButton onClick={handleStep(keyName)} completed={valid[keyName]} className={classNames({'mobile': !smUp })}>
              {smUp && steps[keyName]['label']}
            </StepButton>
          </Step>
        ))}
      </StyledStepper>
    </>
  );
}


const mapDispatchToProps = dispatch => ({
  redirectTo: (url) => dispatch(push(url))
});

export default connect(
  null,
  mapDispatchToProps
)(WillSectionStepper);
