import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash'
import { Box, Button, Divider, Grid, Hidden, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { connect } from 'react-redux';
import { ChevronRight } from 'react-feather';
import {
  createRecipientShares as createRecipientSharesAction,
  deleteRecipientShares as deleteRecipientSharesAction,
  getCharityShares as getCharitySharesAction,
  getRecipientShares as getRecipientSharesAction,
  submitCharityShares as submitCharitySharesAction,
  submitShares as submitSharesAction
} from "redux/actions/wasiyyah";
import { enqueueSnackbar as enqueueSnackbarAction } from 'redux/actions/snackbar'
import RadioField from "components/atoms/RadioField/RadioField";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import WillSectionStepper from "components/organisms/Stepper/WillSectionStepper";
import { sectionSlug, sectionSteps } from "views/will/Wassiya/Steps";
import SectionResolver from "utils/SectionsResolver";
import AddShareRecipientDialog from "components/molecules/AddShareRecipientDialog/AddShareRecipientDialog";
import ShareListItem from "components/molecules/ShareListItem/ShareListItem";
import MobileHelpText from "components/molecules/HelpText/MobileText";
import StandardHelpText from "components/molecules/HelpText/StandardHelpText";

const stepIndex = 1
const [sectionName, section, nextSection] = SectionResolver(sectionSteps, stepIndex)

const WasiyyahGifts = ({
     user,
     charities,
     submitShares,
     charityShares,
     getCharityShares,
     submitCharityShares,
     recipientShares,
     getRecipientShares,
     createRecipientShares,
     deleteRecipientShares,
     will,
     getWills,
     partialUpdateWill,
     enqueueSnackbar
  }) => {


  const formikRef = useRef(null);

  useEffect(() => {
    getCharityShares(() => {
      getRecipientShares(() => {
        setSharesLoaded(true)
      })

    })
  }, [])

  // const [currentRecipientShares, setCurrentRecipientShares] = useState([])
  const [sharesLoaded, setSharesLoaded] = useState(false)


  const helpText = "" +
    "<p class='font-primary'><b>Should I include my immediate family in this section?</b></p>" +
    "<p>Please don’t include your parents, your spouse, nor your children as they, if applicable, will inherit a share of your wealth already.</p>" +
    "<p>Your details which were recorded in step 1 will be used to calculate the share they each inherit, according to the guidelines set out in the Qur'an and Sunnah. This will appear in your letter of wishes attached to your Islamic will.</p>" +
    "<p class='font-primary'><b>How much wealth can I leave to other than my immediate family?</b></p>" +
    "<p>As a Muslim, you can leave up to 33% of your total wealth to a charity and to friends. You may also include relatives in this section who aren't entitled to inherit from you.</p>" +
    "<p>Leaving a gift is optional.</p>"

  const getTotalShare = () => {

    let totalSharesPercent = 0
    recipientShares.forEach(function (item, index) {
      if(_.isInteger(item.percent)){
        totalSharesPercent +=item.percent
      }
    });

    if(!_.isEmpty(charityShares)){
      totalSharesPercent += charityShares.percent
    }
    return totalSharesPercent

  }

  const addRecipientInit = () => {
    if(!will.progress.wassiya.gifts) return "yes"
    else return _.isEmpty(recipientShares)? "no" : "yes"
  }

  const onShareChange = (add_recipient) => {
    let totalSharesPercent = 0
    if(add_recipient === "yes") {
      formikRef.current.values.recipient_shares.forEach(function (item, index) {
        if(_.isInteger(item.percent)){
          totalSharesPercent +=item.percent
        }
      });
    }

    if(formikRef.current.values.charity_shares){
      totalSharesPercent += formikRef.current.values.charity_shares
    }
    formikRef.current.setFieldValue('total_shares', totalSharesPercent)
  }

  const postCreateHook = (data) => {
    formikRef.current.values.recipient_shares.push(data.data)
  }

  const postDeleteHook = (id) => {
    formikRef.current.setFieldValue('recipient_shares', formikRef.current.values.recipient_shares.filter(item => item.id !== id))
    setTimeout(function() { onShareChange("yes") }, 200);
  }


  return (
    <>
    {sharesLoaded && <>
    <Helmet><title>{section["label"]}</title></Helmet>
    <Grid container direction="row" spacing={5}>
      <Hidden smDown>
        <Grid item sm={12}  xs={12} md={4}>
          <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
        </Grid>
      </Hidden>
      <Grid item sm={12} md={4}>
        <Hidden mdUp>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={4}>
              <Box mb={3}>
                <WillSectionStepper activeStepIndex={stepIndex} steps={sectionSteps} valid={will.progress[sectionSlug]}/>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant="h1" align="center">{section["label"]}</Typography>
        <MobileHelpText text={helpText} collapsable={true} />
        <Formik
          innerRef={formikRef}
          initialValues={{
            add_recipient: addRecipientInit(),
            has_charity: _.isEmpty(charityShares)? "no" : "yes",
            recipient_shares: recipientShares,
            charity_shares:   charityShares.percent,
            total_shares:  getTotalShare()
          }}
          enableReinitialize={false}
          validationSchema={
            yup.object().shape({

              // charity_shares: yup.number().when('has_charity', {
              //     is: "yes",
              //     then: yup.number().min(1, ' ').required(' ')
              // }),
              // total_shares: yup.number().min(0, ' ').max(33, ' ').required(),
              // recipient_shares: yup.array().when('add_recipient', {
              //   is: "yes",
              //   then: yup.array().of(yup.object().shape({
              //     percent: yup.number().typeError(' ').min(1, ' ').required(' ')
              //   }))
              // })
            })}

          onSubmit={async (values, { setSubmitting, setFieldError }) => {
            let valid = true
            let message = ''
            if(values.add_recipient === "yes" && _.isEmpty(recipientShares)){
              message = 'Please add at least one recipient'
              valid = false
            }

            values.recipient_shares.forEach(function (share, index) {
              if(share.percent === '' || share.percent === null || share.percent === 0){
                message = 'You must enter an amount'
                setFieldError(`recipient_shares[${index}].percent`, ' ')
                valid = false
              }
            });

            if(values.charity_shares === '' || values.charity_shares === null || values.charity_shares === 0){
              message = 'You must enter an amount'
              setFieldError('charity_shares', ' ')
              valid = false
            }

            if(!valid){
              enqueueSnackbar({
                message: message,
                options: {variant: "error"},
              })
              return false
            }

            if(values.total_shares > 33){
              setFieldError('total_shares', ' ')
              enqueueSnackbar({
                message: "The total must be less than or equal to 33%",
                options: {variant: "error"},
              })
              return false;
            }


            if(values.add_recipient === 'no'){
              deleteRecipientShares()
              values['recipient_shares'] = []
            }

            submitShares(values)

            let progress = will.progress
            progress[sectionSlug][sectionName] = true
            await partialUpdateWill(will.id, {'progress': progress})
            getWills()
          }}
        >

          {({isSubmitting, setFieldValue,getVtouched, errors, values, setFieldError, validateField}) => {
            return (
              <Form onChange={() => setTimeout(function() {  onShareChange(values.add_recipient) }, 100)} >
                <Box mt={5}>
                  <Box mb={1}>
                    <Typography variant="h4">Would you like to leave a share of your wealth to other than your immediate family?</Typography>
                  </Box>
                    <RadioField
                      name="add_recipient"
                      values={{
                        "yes": "Yes",
                        "no": "No",
                      }}
                      horizontal
                      onChange={e => {
                        setFieldValue("add_recipient", e.target.value);
                        let value = e.target.value
                        setTimeout(function() {  onShareChange(value) }, 150)
                      }}
                    />

                  {values.add_recipient === 'yes' && (
                    <Box mt={2}>
                      <Box mb={1}>
                        <Typography variant="h4">Who would you like to leave it to?</Typography>
                      </Box>
                        <FieldArray name="recipient_shares" >

                          {({push}) => (
                            <div>
                               {recipientShares.map((recipient, index) => (
                                  <Box mb={1}>
                                    <ShareListItem
                                      key={recipient.id}
                                      header={recipient.full_name}
                                      description={recipient.relationship}
                                      inputIndex={index}
                                      percentInputName='recipient_shares'
                                      idInputValue={recipient.id}
                                      onChangeHook={onShareChange}
                                      editButton={<AddShareRecipientDialog textButton="Edit" recipient={recipient} postDeleteHook={postDeleteHook} />}
                                      arrayField
                                    />
                                  </Box>
                                ))}
                            </div>

                          )}

                        </FieldArray>
                        <AddShareRecipientDialog
                          dialogButtonText="Add recipient"
                          postCreateHook={postCreateHook}
                        />
                    </Box>
                  )}

                  {(values.add_recipient === "yes" || !_.isEmpty(charityShares)) &&
                    <Box mt={3} mb={3}>
                      <Divider/>
                    </Box>
                  }

                  {!_.isEmpty(charityShares) &&
                    <ShareListItem
                      header={charityShares.charity_name}
                      description="Charity"
                      idInputValue={charityShares.id}
                      percentInputName="charity_shares"

                    />
                  }

                  {(values.add_recipient === "yes" || !_.isEmpty(charityShares)) &&
                    <Box mt={2}>
                      <ShareListItem
                        header="TOTAL"
                        description="Maximum 33%"
                        percentInputName="total_shares"
                        percentInputDisabled
                      />
                    </Box>
                  }
                </Box>

                <Box mt={3} mb={3}>
                  <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} fullWidth>Save and continue <ChevronRight size={20}/></Button>
                </Box>
              </Form>
            )
          }}
        </Formik>
      </Grid>
      <Hidden smDown>
        <Grid item sm={12} xs={12} md={4}>
          <Box mt={10}>
            <StandardHelpText text={helpText}/>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  </>}</>
  )
};

const mapStateToProps = ({ account, global, wasiyyah, wills }) => ({
  user: account.user,
  charityShares: wasiyyah.charity_shares,
  recipientShares: wasiyyah.recipient_shares,
  formError: global.formError,
  will: wills.wills[0]
});

const mapDispatchToProps = dispatch => ({
  getWills: () => dispatch(getWillsAction()),
  submitShares: (formData) => dispatch(submitSharesAction(formData)),
  getCharityShares: (postSuccessHook) => dispatch(getCharitySharesAction(postSuccessHook)),
  submitCharityShares: (formData) => dispatch(submitCharitySharesAction(formData)),
  getRecipientShares: (postSuccessHook) => dispatch(getRecipientSharesAction(postSuccessHook)),
  createRecipientShares: (formData) => dispatch(createRecipientSharesAction(formData)),
  deleteRecipientShares: () => dispatch(deleteRecipientSharesAction()),
  partialUpdateWill: (willID, formData) => dispatch(partialUpdateWillAction(willID, formData, () => {} , nextSection["url"])),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbarAction(notification, dispatch))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WasiyyahGifts);
