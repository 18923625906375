import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Typography } from '@material-ui/core'
import { Plus, Save, Trash2 } from 'react-feather';
import CloseIcon from '@material-ui/icons/Close';
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextField from "components/atoms/TextField/TextField";
import { connect } from "react-redux";
import {
  createChild as createChildAction,
  deleteChild as deleteChildAction,
  partialUpdateChild as partialUpdateChildAction
} from "redux/actions/people";
import SelectField from "components/atoms/SelectField/SelectField";
import { birthDateJoin, birthDateSplit } from "utils/birthDateParser";
import styled from "styled-components";
import { theme } from "theme/mainTheme";


const StyledDeleteButton = styled(Button)`
  margin-right: 10px;
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${theme.palette.grey.A200}
`


const  AddChildDialog = ({ dialogButtonText, iconButton=false, child=null, createChild, partialUpdateChild, deleteChild, textButton='', })  => {
  const [open, setOpen] = useState(false);

  const [birthday_day, birthday_month, birthday_year] = birthDateSplit(child ? child.birth_date : null)


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const postSuccessHook = () => {
      handleClose();
  }

  const handleDelete = () => {
    deleteChild(child.id)
    handleClose();
  }

  return (
    <div>
      {textButton &&
        <Link href="#" onClick={handleClickOpen} cursor="pointer"> {textButton}</Link>
      }

      {!textButton &&
      <Button variant="contained" color="secondary" onClick={handleClickOpen} fullWidth> {dialogButtonText}
        <Plus size={20}/></Button>
      }
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Typography variant="h2">{child? "Edit" : "Add"} child</Typography>
          <StyledCloseButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </StyledCloseButton>
        </DialogTitle>

          <Formik
            initialValues={{
              full_name: child? child.full_name : '',
              birthday_day: birthday_day,
              birthday_month: birthday_month,
              birthday_year: birthday_year,
              gender: child ? child.gender : ''
            }}
            validationSchema={
              yup.object().shape({
                full_name: yup.string()
                .label('Full legal name')
                .required(),
              birthday_day: yup.number().typeError('DD').min(1, 'DD').max(31, 'DD')
                  .label('Day')
                  .required('DD'),
              birthday_month: yup.number().typeError('MM').min(1, 'MM').max(12, 'MM')
                  .label('Month')
                  .required('MM'),
              birthday_year: yup.number().typeError('YYYY').max(new Date().getFullYear(), 'YYYY').test('len', 'YYYY', val => val && val.toString().length === 4 )
                  .label('Year')
                  .required('YYYY'),
              gender: yup.string()
                  .label('Gender')
                  .required()
              })}

            onSubmit={async(values) => {
              values["birth_date"] = birthDateJoin(values.birthday_day, values.birthday_month, values.birthday_year)
              if(child) await partialUpdateChild(child.id, values, postSuccessHook)
              else await createChild(values, postSuccessHook)
            }}
          >
            {({isSubmitting, touched, errors}) => {
              return (
                <DialogContent>
                <Form>
                  <TextField size="small" name="full_name" label="Full legal name" fullWidth/>
                  <SelectField
                    size="small"
                    name="gender"
                    label="Gender"
                    values={{
                      "male": "Male",
                      "female": "Female",
                    }}
                    fullWidth
                  />
                  <Box mt={1}>
                    <Box mb={1}>
                      <Typography variant="h4">Date of birth</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <TextField size="small" type="number" name="birthday_day" label="Day" helpText="DD" />&nbsp;
                      <TextField size="small" type="number" name="birthday_month" label="Month" helpText="MM"/>&nbsp;
                      <TextField size="small" type="number" name="birthday_year" label="Year" helpText="YYYY"/>
                    </Box>
                  </Box>
                  <Box mb={2}>
                      <Grid container spacing={1}>
                        {child &&
                          <Box clone order={{ xs: 2, sm: 1}}>
                            <Grid item xs={12} sm={6}  lg={6} >
                              <StyledDeleteButton variant="contained" color="secondary" startIcon={<Trash2 size={20}/>} onClick={() => handleDelete()} fullWidth>Remove child</StyledDeleteButton>
                            </Grid>
                          </Box>
                        }
                      <Box clone order={{ xs: 1, sm: 2 }}>
                        <Grid item xs={12} sm={child? 6 : 12} >
                          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} startIcon={<Save/>} fullWidth>Save child</Button>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Form>
                </DialogContent>
              )
            }}
          </Formik>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  createChild: (formData, postSuccessHook) => dispatch(createChildAction(formData, postSuccessHook)),
  partialUpdateChild: (childID, formData, postSuccessHook) => dispatch(partialUpdateChildAction(childID, formData, postSuccessHook)),
  deleteChild: (childID) => dispatch(deleteChildAction(childID)),
});

export default connect(
  null,
  mapDispatchToProps
)(AddChildDialog);
