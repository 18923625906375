import React from "react";
import { Box, Button, Tooltip, Typography } from "@material-ui/core";
import Paper from "components/atoms/Paper/Paper";
import { Link as RouterLink } from "react-router-dom";
import { CheckCircle, Edit, Warning } from "@material-ui/icons";
import { theme } from "theme/mainTheme";
import { Edit2 } from 'react-feather';


const SectionBox = ({legend, title, description, startPath, active, disabled, completed, valid, status}) => {

  return (
    <>
      <Paper active={active}>
        <Box mb={2} color={disabled ? "text.disabled" : "text.primary"} display="flex" justifyContent="space-between">
          <span>{legend}</span>
          {valid && <CheckCircle style={{color: theme.palette.success.main}}/>}
          {completed && !valid && <Warning style={{color: theme.palette.warning.main}}/>}
        </Box>
        <Typography variant="h4">{title}</Typography>
        <span className="font-secondary">{description}</span>
        <Box mt={2.5}>
          <Tooltip title={status === 'reviewing' ? "Please wait for review to complete." : ""} >
            <span>
              {process.env.REACT_APP_PRESS_RELEASE ?
                <Button component={RouterLink} to={startPath} type="submit" variant="contained" color="primary"
                        disabled={true} disableElevation
                        startIcon={<Edit2 size={20}/>}> {completed ? "Edit" : "Start now"} </Button>
                :
                <Button component={RouterLink} to={startPath} type="submit" variant="contained" color="primary"
                        disabled={disabled || status === 'reviewing'} disableElevation
                        startIcon={<Edit2 size={20}/>}> {completed ? "Edit" : "Start now"} </Button>
              }
            </span>
          </Tooltip>
        </Box>
      </Paper>
    </>
  )
}

export default SectionBox
