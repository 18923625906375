import authAxios from "utils/authAxios";
import * as actions from 'redux/actions/wills/types'

export const getWills = () => {
  return {
    types: [
      actions.GET_WILLS_START,
      actions.GET_WILLS_SUCCESS,
      actions.GET_WILLS_ERROR
    ],
    callAPI: () => authAxios().get('wills/wills/'),
  }
}

export const partialUpdateWill = (willID, formData,  postSuccessHook = () => {},  redirectTo = false) => {
  return {
    types: [
      actions.PARTIAL_UPDATE_WILL_START,
      actions.PARTIAL_UPDATE_WILL_SUCCESS,
      actions.PARTIAL_UPDATE_WILL_ERROR
    ],
    postSuccessHook: postSuccessHook,
    payload: { willID },
    redirectTo: redirectTo,
    callAPI: () => authAxios().put(`wills/wills/${willID}/`, {...formData}),
  }
}

export const getWillDraft = () => {
  return {
    types: [
      actions.GET_WILL_DRAFT_START,
      actions.GET_WILL_DRAFT_SUCCESS,
      actions.GET_WILL_DRAFT_ERROR
    ],
    callAPI: () => authAxios().get('wills/draft/'),
  }
}


export const generateWillRevision = (postSuccessHook = () => {}) => {
  return {
    types: [
      actions.GENERATE_WILL_REVISION_START,
      actions.GENERATE_WILL_REVISION_SUCCESS,
      actions.GENERATE_WILL_REVISION_ERROR
    ],
    postSuccessHook: postSuccessHook,
    callAPI: () => authAxios().post('wills/revisions/'),
  }
}

export const purchaseWill = (formData, postSuccessHook = () => {}, postErrorHook = () => {}) => {
  return {
    types: [
      actions.PURCHASE_WILL_START,
      actions.PURCHASE_WILL_SUCCESS,
      actions.PURCHASE_WILL_ERROR
    ],
    postSuccessHook: postSuccessHook,
    postErrorHook: postErrorHook,
    errorMessages: [{variant: "error", message: "Payment was unsuccessful, please try again later"}],
    callAPI: () => authAxios().post('wills/purchase/', {...formData}),
  }
}


export const submitWill = (postSuccessHook = () => {}, postErrorHook = () => {}) => {
  return {
    types: [
      actions.SUBMIT_WILL_START,
      actions.SUBMIT_WILL_SUCCESS,
      actions.SUBMIT_WILL_ERROR
    ],
    postSuccessHook: postSuccessHook,
    postErrorHook: postErrorHook,
    successMessages: [{ variant: "success", message: "Your will has been submitted for a review" }],
    callAPI: () => authAxios().post('wills/submit/'),
  }
}
