export const GET_ASSETS_START = 'GET_ASSETS_START';
export const GET_ASSETS_SUCCESS = 'GET_ASSETS_SUCCESS';
export const GET_ASSETS_ERROR = 'GET_ASSETS_ERROR';

export const CREATE_ASSET_START = 'CREATE_ASSET_START';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_ERROR = 'CREATE_ASSET_ERROR';

export const UPDATE_ASSET_START = 'UPDATE_ASSET_START';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_ERROR = 'UPDATE_ASSET_ERROR';

export const DELETE_ASSET_START = 'DELETE_ASSET_START';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_ERROR = 'DELETE_ASSET_ERROR';

export const GET_ASSET_SHARES_START = 'GET_ASSET_SHARES_START';
export const GET_ASSET_SHARES_SUCCESS = 'GET_ASSET_SHARES_SUCCESS';
export const GET_ASSET_SHARES_ERROR = 'GET_ASSET_SHARES_ERROR';

export const CREATE_ASSET_SHARES_START = 'CREATE_ASSET_SHARES_START';
export const CREATE_ASSET_SHARES_SUCCESS = 'CREATE_ASSET_SHARES_SUCCESS';
export const CREATE_ASSET_SHARES_ERROR = 'CREATE_ASSET_SHARES_ERROR';

export const UPDATE_ASSET_SHARES_START = 'UPDATE_ASSET_SHARES_START';
export const UPDATE_ASSET_SHARES_SUCCESS = 'UPDATE_ASSET_SHARES_SUCCESS';
export const UPDATE_ASSET_SHARES_ERROR = 'UPDATE_ASSET_SHARES_ERROR';

export const DELETE_ASSET_SHARES_START = 'DELETE_ASSET_SHARES_START';
export const DELETE_ASSET_SHARES_SUCCESS = 'DELETE_ASSET_SHARES_SUCCESS';
export const DELETE_ASSET_SHARES_ERROR = 'DELETE_ASSET_SHARES_ERROR';

export const GET_CHARITIES_START = 'GET_CHARITIES_START';
export const GET_CHARITIES_SUCCESS = 'GET_CHARITIES_SUCCESS';
export const GET_CHARITIES_ERROR = 'GET_CHARITIES_ERROR';

