import * as actions from 'redux/actions/account/types'
import update from 'immutability-helper';

const initialState = {
  user: {},
}

const accountReducer = (state = initialState, action) => {
  switch (action.type){

    case actions.GET_ACCOUNT_START:
      return {...state, formError: false}
    case actions.GET_ACCOUNT_SUCCESS:
      return {...state, user: action.data}
    case actions.GET_ACCOUNT_ERROR:
      return {...state}

    case actions.PARTIAL_UPDATE_ACCOUNT_START:
      return {...state, formError: false}
    case actions.PARTIAL_UPDATE_ACCOUNT_SUCCESS:
      return {...state, user: action.data}
    case actions.PARTIAL_UPDATE_ACCOUNT_ERROR:
      return {...state, globalError: action.error.response.data.detail}

    case actions.DELETE_ACCOUNT_START:
      return {...state, formError: false}
    case actions.DELETE_ACCOUNT_SUCCESS:
      return {...state, user: action.data}
    case actions.DELETE_ACCOUNT_ERROR:
      return {...state, globalError: action.error.response.data.detail}

    case actions.SET_ACTIVE:
      return update(state, {
        user: {
          "is_active" : { $set: true }
        }
      })

    default:
      return state
  }
};

export default accountReducer

