import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@material-ui/core";
import _ from 'lodash'
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Repeat, Edit2 } from 'react-feather';
import styled from "styled-components";
import { theme } from "theme/mainTheme";
import { connect } from "react-redux";
import Checkout from "components/organisms/Checkout/Checkout";
import WillDownload from "components/molecules/WillDownload/WillDownload";
import classNames from 'classnames/bind';
import { partialUpdateAccount as partialUpdateAccountAction } from "redux/actions/account";
import { routes } from "routes";
import { getWills as getWillsAction, partialUpdateWill as partialUpdateWillAction } from "redux/actions/wills";
import { Link as RouterLink } from "react-router-dom";
import { activationResend as activationResendAction } from "redux/actions/auth/Activation";

const StyledAccordion = styled(Accordion)`
  
  .MuiTypography-body1{
    
    font-size: 1.8rem;
    margin-left: 10px;
  }
  
  &.MuiPaper-root{
    background-color: transparent;
    box-shadow: none;
  }
  
  .MuiAccordionSummary-root {
    padding: 0;
  }
  
  .MuiStepIcon-root{
    color: rgba(0, 0, 0, 0.38)
  }
  
  .MuiStepIcon-root.MuiStepIcon-active circle {
    color: ${theme.palette.primary.main}
  }
  
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: ${theme.palette.success.main}
  }
  
  .MuiAccordionSummary-expandIcon{
    color: ${theme.palette.text.primary};
  }
  
  .MuiStepIcon-text {
    font-weight: bold;
    font-size: 1.4rem;
    color: ${theme.palette.text.primary};
  }
  
  .MuiAccordionDetails-root{
    padding: 0;
    margin-left: 24px;
    margin-right: 20px;
  }
  
  .activation-warning {
    margin-top: 5px;
    padding: 5px;
    background-color: ${theme.palette.background.highlight};
  }

`

const StyledWillDownload = styled(WillDownload) `
  background-color: ${theme.palette.success.main};
  width: 100%;
  color: white;
  &:hover{
    background-color: ${theme.palette.success.main};
  }
`


const SummaryBox = ({ user, will, activationResend }) => {

  const [expanded0, setExpanded0] = useState(false)
  const [expanded1, setExpanded1] = useState(false)
  const [expanded2, setExpanded2] = useState(false)

  useEffect(() => {
    if(!_.isEmpty(will)){
      if(!will.paid || (will.changed && will.valid_summary['all']) || will.status === 'change_requested'){
        setExpanded0(true)
      }else{
        setExpanded0(false)
      }

      if(will.status === 'reviewing' || will.status === 'change_requested'){
        setExpanded1(true)
      }

      if(will.status === 'approved'){
        setExpanded2(true)
      }
    }

  }, [will])

  const StepIcon = ({ active= false, completed = false, label, icon=null }) => (
    completed ?
      <svg className="MuiSvgIcon-root MuiStepIcon-root MuiStepIcon-active MuiStepIcon-completed" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z"></path>
      </svg>
      :
      <svg className={classNames("MuiSvgIcon-root", "MuiStepIcon-root", {"MuiStepIcon-active": active})} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <circle cx="12" cy="12" r="12"></circle>
        {icon?
          icon
        :
          <text className="MuiStepIcon-text" x="12" y="16" text-anchor="middle">{label} </text>
        }

      </svg>
  );

  return (
    <>
      {!_.isEmpty(will) &&
      <Box>

        <Box mb={1}>
          <StyledAccordion expanded={expanded0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={() => setExpanded0(!expanded0)}>
              <StepIcon label={1} completed={will.paid} active={!will.paid}/>
              <Typography><b>Create your Islamic will</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
                {!will.valid_summary['all'] && !will.paid &&
                <Box width='100%'>
                  <Typography className="font-secondary">This should take around 20 minutes.</Typography>
                  <Box mt={1}>

                    <Button component={RouterLink} to={routes.will} type="submit" variant="contained" color="primary" disableElevation fullWidth startIcon={<Edit2 size={20}/>}> Create my will</Button>
                  </Box>
                </Box>
                }

                {will.valid_summary['all'] && !will.paid &&
                  <Box>
                    <Typography className="font-secondary">Congratulations! Your Islamic will is now ready for expert checking. Upon payment, our in-house
                      solicitor will check your will.
                    </Typography>
                    {will.valid_summary['all'] && !user.is_active &&
                      <Typography className="font-secondary activation-warning" >We need you to confirm your email address before you can submit your will for checking.
                        <br/><RouterLink href="#" className="MuiTypography-root MuiLink-root MuiLink-underlineHover" onClick={() => activationResend(user.email)} >Resend activation email</RouterLink></Typography>
                    }
                    <Box mt={1}>
                      <Checkout/>
                    </Box>
                  </Box>
                }

              {will.status === 'approved' && will.paid && !will.changed &&
                <Typography className="font-secondary">All done.</Typography>
              }

              {will.valid_summary['all'] && will.status === 'approved' &&  will.paid && will.changed &&
                  <>
                    <Typography className="font-secondary">
                      You have made changes to your Islamic will.
                    </Typography>
                  </>
                }

               {will.status === 'change_requested' &&
                  <>
                    <Typography className="font-secondary">
                      You need to make changes to your Islamic will.
                    </Typography>
                <Box mt={1}>
                    <Button component={RouterLink} to={routes.will} type="submit" variant="contained" color="primary" disableElevation fullWidth startIcon={<Edit2 size={20}/>}> Edit your will </Button>
                  </Box>
                  </>
                }


            </AccordionDetails>
          </StyledAccordion>
        </Box>
        <Box mb={1}>
          <StyledAccordion expanded={expanded1}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={() => setExpanded1(!expanded1)}>
              <StepIcon label={2} completed={will.status === 'approved'} active={will.status === 'reviewing'}/>
              <Typography><b>Have it checked</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="font-secondary">
                {will.paid ?
                  <>
                    {will.status === 'reviewing' && "Your Islamic will is being checked by our in-house solicitor. You will be notified via email when your will is ready to be downloaded." }
                    {will.status === 'approved' && "Your Islamic will has been approved by our in-house solicitor."}
                    {will.status === 'change_requested' && "Once you resubmit your Islamic will, it will be checked again by our in-house solicitor. You will be notified via email when your will is ready to be downloaded. "}
                  </>
                  :
                  <>
                    Our in-house solicitor checks your Islamic will within 7 working days to let you know if anything needs to be changed or clarified.
                  </>
                }
              </Typography>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
        <Box mb={1}>
          <StyledAccordion expanded={expanded2}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} onClick={() => setExpanded2(!expanded2)}>
              <StepIcon label={3} completed={false} active={will.status === 'approved'}/>
              <Typography><b>Print and sign</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
              <Typography className="font-secondary">
                You must print and sign your {will.status === 'approved' && will.changed && "updated" } Islamic will in front of two witnesses to make it legally binding. You can download and print your Islamic will at any time.
              </Typography>
                <Box mt={1}>
                  {will.status === 'approved' && <StyledWillDownload fullWidth buttonText="Download your will" />}
                </Box>
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
        <Box mb={1}>
          <StyledAccordion defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
               <StepIcon icon={<Repeat size="16" x={4} y={4} color='#000'/>} completed={false} active={will.status === 'approved'}/>
              <Typography><b>Make changes anytime for free </b></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography className="font-secondary">
                  Make changes to your Islamic will for free anytime in the first year. Support is given whenever you need it.
                </Typography>
                {(will.status === 'approved' || will.status === 'change_requested') &&
                  <Box mt={1}>
                    <Button component={RouterLink} to={routes.will} type="submit" variant="contained" color="primary" disableElevation fullWidth startIcon={<Edit2 size={20}/>}> Edit your will </Button>
                  </Box>
                }
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Box>
      </Box>
      }
    </>
  )
}

const mapStateToProps = ({ account, wills }) => ({
  user: account.user,
  will: wills.wills[0]

});

const mapDispatchToProps = dispatch => ({
  activationResend: (email) => dispatch(activationResendAction(email)),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryBox);

