import React, { MouseEvent, useState } from "react";
import { Button, Link, Menu, MenuItem, Typography } from "@material-ui/core";
import { Settings, HelpCircle, ChevronDown, LogOut } from 'react-feather';
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { logout as logoutAction } from "redux/actions/auth/Login";
import { routes } from "routes";
import styled from "styled-components";
import { theme } from 'theme/mainTheme'
import segmentTrack from "utils/SegmentIO";


const StyledButton = styled(Button)`
  font-family: ${theme.typography.fontFamily};
  font-weight:normal;
`

const StyledMenuItem = styled(MenuItem)`
  font-size: ${theme.typography.textSize.medium};
  padding: 15px 35px;
  
  &.MuiLink-root{
    color: ${theme.palette.text.primary}
  }

`
const UserMenu = ({ user, logout }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    segmentTrack("Menu | Clicked dropdown")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLogOut = (event) => {
    setTimeout(() => {
      logout()
    }, 100);
  }

  return (

    <div>
      <StyledButton aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
        {user.preferred_name} <ChevronDown size={20}/>
      </StyledButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            marginTop: '40px',
            marginLeft: '0'
          }
        }}
      >
        <StyledMenuItem component={RouterLink} to={routes.account} onClick={() => {segmentTrack("Menu | Clicked manage account"); handleClose();}}> <Settings size={20}/> &nbsp; Manage account</StyledMenuItem>
        <StyledMenuItem onClick={() => {segmentTrack("Menu | Clicked help"); handleClose();}} component={Link} href="https://truewills.co.uk/faqs/" target="_blank" className="MuiMenuItem-root"> <HelpCircle size={20}/> &nbsp; Help</StyledMenuItem>
        <StyledMenuItem onClick={() => {segmentTrack("Menu | Clicked save and log out"); handleLogOut();}}> <LogOut size={20}/> &nbsp; Save and Log out</StyledMenuItem>
      </Menu>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
});

export default connect(
  null,
  mapDispatchToProps
)(UserMenu);


