import { makeStyles } from "@material-ui/core/styles";
import { SnackbarProvider as NotistackProvider } from "notistack";
import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "theme/mainTheme";

const useStyles = makeStyles((theme) => ({
  variantSuccess: { backgroundColor: `${theme.palette.success.main}!important` },
  variantError: { backgroundColor: `${theme.palette.error.main}!important` },
  variantWarning: { backgroundColor: `${theme.palette.warning.main}!important` },
  variantInfo: { backgroundColor: `${theme.palette.info.main}!important` }
}));

const SnackBarProvider = ({ children }) => {
  const classes = useStyles();
  return (
    <NotistackProvider
      classes={classes}
      children={children}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    />
  );
};

export default SnackBarProvider;
